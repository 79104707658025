import React, { useEffect, useState, useRef } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Form, InputGroup, Button, Nav, Tab, Table } from 'react-bootstrap';
import config from "../../config"
import "./detail.css"
import { getFormDetailsById } from "../../Action/action"
import Web3WalletProvider from "../../ConnectWallet/walletProvider"
import { useDisconnect, useAccount } from "wagmi";
import { getUsdtBalance, ApproveUsdtToken, BuyTokenUsdt, UsdtEarned, ClaimToken, TokenSaleDuration, RefundTokens, UserUsdt, UserToken,RefundStat } from "../../Web3/Connector"
import { toast, Toaster } from 'react-hot-toast';
import ReactApexChart from 'react-apexcharts';
import ReadMore from "./readmore";
import { FaArrowRightLong } from "react-icons/fa6";
import { showLoader,hideLoader } from "../loader";



export default function Detail() {
    const { id } = useParams()
    const [form, setForm] = useState([]);
    const loginDetails = useSelector((state) => state.auth.LoginDetails);
    const walletConnnected = useSelector((state) => state.auth.walletConnnected);
    const navigate = useNavigate();
    const _useWeb3WalletProvider = Web3WalletProvider()
    const { address, isConnected } = useAccount()
    const [connectWalletNow, setconnectWalletNow] = useState(false);
    const [Waddress, setAddress] = useState("");

    const [UsdtAmount, setUsdtAmount] = useState(0);
    const [token, setToken] = useState(0);
    const [isUsdtApproved, setisUsdtApproved] = useState(false);
    const _useDisconnect = useDisconnect()
    const [isProviderLoaded, setisProviderLoaded] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [buyOption, setBuyOption] = useState("1")
    const [loader, setLoader] = useState(false)
    const [err,setError] = useState('')


    useEffect(() => {
        console.log(isConnected, "IsConnect")
        console.log(address, "ADDRESS")
        console.log(walletConnnected, "WALLET")

        if (walletConnnected) {
            setconnectWalletNow(false)
        }
        setAddress(address)
    }, [walletConnnected])

    const disconnectWallet = () => {
        _useDisconnect.disconnect()
    }

    useEffect(() => {
        if (!isProviderLoaded) {
            _useWeb3WalletProvider.then((data) => {
                if (data) {
                    setisProviderLoaded(true)
                }
            })
        }
    }, [_useWeb3WalletProvider])
    const buttonRef = useRef(null); // Add a ref for the button

    useEffect(() => {
        // AutoBNBally click the button after 2 seconds
        const timer = setTimeout(() => {
            if (buttonRef.current) {
                buttonRef.current.click();
            }
        }, 2000);

        return () => clearTimeout(timer); // Cleanup the timer
    }, []);

    useEffect(() => {
        if(isProviderLoaded) {
            getFormData()
        }
    }, [isProviderLoaded])

const getFormData = async () => {
        try {
            const response = await getFormDetailsById({ id });
            console.log('FORM', response.data)
            if (response.success) {
                if (response.data.length > 0) {

                    let dResult = await checkDuration(response.data[0])
                    response.data[0].newD = dResult

                    let walletBalance = await walletUsdtBalance(response.data[0])
                    response.data[0].bal = walletBalance

                    let capUsdt = await usdtEarnings(response.data[0])
                    response.data[0].usdtBal = capUsdt

                    let tokenresult = await usertoken(response.data[0])
                    response.data[0].tokenUser = tokenresult

                    let usdtresult = await userusdt(response.data[0])
                    response.data[0].usdtUser = usdtresult

                    let tok = await tokenomics(response.data[0])
                    setChart(tok)

                    let refund = await refundStat(response.data[0])
                    response.data[0].isRefunded = refund
                }
                //console.log('FORM2', response.data)
                setForm(response.data)
            }
        } catch (error) {
            console.log(error);
        }
    };

const checkDuration = async (formData) => {
        let request = {
            walletProvider: formData.chainRPCUrl,
            tokenAddress: formData.token_address,
            preSaleAddress: formData.presaleContract,
            networkId: formData.chainNetworkId
        }
        //console.log('TRE', request)
        let endTime = await TokenSaleDuration(request)
        let currentTime = Math.floor(Date.now() / 1000);
        //console.log('TIME', (Number(endTime.duration)), currentTime)
        return currentTime > Number(endTime.duration)
    }

const walletUsdtBalance = async (formData) => {
        let request = {
            walletProvider: formData.chainRPCUrl,
            tokenAddress: formData.token_address,
            tokenAmount: formData.token_amount,
            UsdtPrice: formData.usdt_price,
            preSaleAddress: formData.presaleContract,
            usdtAddress: formData.usdtContract,
            networkId: formData.chainNetworkId,
            buyAmount: UsdtAmount
        }
        const res = await getUsdtBalance(request)
        //console.log('BAL', res)
        //setUsdtBalance(res?.balance || 0);
        return (res?.balance || 0)
    }

const usdtEarnings = async (formData) => {
        try {
            let request = {
                walletProvider: formData.chainRPCUrl,
                tokenAddress: formData.token_address,
                preSaleAddress: formData.presaleContract,
                networkId: formData.chainNetworkId
            }
            let bal = await UsdtEarned(request)
            console.log('UUUUUU', bal)
            return bal.usdtAmount
        } catch (error) {
            console.log(error)
        }
    }

const usertoken = async (formData) => {
        try {
            let request = {
                walletProvider: formData.chainRPCUrl,
                tokenAddress: formData.token_address,
                preSaleAddress: formData.presaleContract,
                networkId: formData.chainNetworkId
            }
            console.log('tokenreq', request)
            let bal = await UserToken(request)
            //console.log('TTTTTT', bal)
            return (bal.tokenAmt) / 10 ** 18
        } catch (error) {
            console.log(error)
        }
    }
const userusdt = async (formData) => {
        try {
            let request = {
                walletProvider: formData.chainRPCUrl,
                tokenAddress: formData.token_address,
                preSaleAddress: formData.presaleContract,
                usdtAddress: formData.usdtContract,
                networkId: formData.chainNetworkId
            }
            //console.log('usdtreq', request)
            let bal = await UserUsdt(request)
            //console.log('UserUSDT', bal)
            return (bal.usdtAmt) / 10 ** 6
        } catch (error) {
            console.log(error)
        }
    }

const tokenomics = async (formData) => {
        const { private_sale, ico, team, partnerships, others } = formData
        return (prevChart => ({
            ...prevChart,
            series: [private_sale, ico, team, partnerships, others]
        }))
    }

const refundStat = async (formData) => {
        try {
            let request = {
                walletProvider: formData.chainRPCUrl,
                tokenAddress: formData.token_address,
                preSaleAddress: formData.presaleContract,
                networkId: formData.chainNetworkId
            }
            let status = await RefundStat(request)
            console.log('refund?', status)
            return status
        } catch (error) {
            console.log(error)
        }
    }

    const onchangehandlerUsdt = (e) => {
        const { value, name } = e.target;
        setisUsdtApproved(false);
        setError('')
            setUsdtAmount(value);
            let tokenVal = parseFloat(value / form[0].usdt_price).toFixed(6)
            if(tokenVal > form[0].token_amount) {
                setError("Cannot buy more than sale amount")
            } else {
                setToken(tokenVal)
            }
        }

    const handleUsdtBuy = async (e) => {
        e.preventDefault()
        if(!isConnected) {
            toast.error("Please connect with the registered wallet address.");
            setconnectWalletNow(true)
            return
          }
          if(address.toUpperCase() != loginDetails.walletAddress.toUpperCase()) {
            toast.error("Please connect with registered wallet address.")
            return
          }
        showLoader()
        try {
            let request = {
                walletProvider: _useWeb3WalletProvider,
                tokenAddress: form.length > 0 ? form[0].token_address : '',
                tokenAmount: form.length > 0 ? form[0].token_amount : '',
                UsdtPrice: form.length > 0 ? form[0].usdt_price : '',
                preSaleAddress: form.length > 0 ? form[0].presaleContract : '',
                usdtAddress: form.length > 0 ? form[0].usdtContract : '',
                networkId: form.length > 0 ? form[0].chainNetworkId : '',
                buyAmount: UsdtAmount
            }
            console.log('RES', request)
            let approval = await ApproveUsdtToken(request)
            if (approval.status) {
                //console.log('APP', approval)
                let resBuy = await BuyTokenUsdt(request);
                //console.log('BUY', resBuy)
                setIsProcessing(false);
                if (resBuy.status) {
                    toast.success(resBuy.message, {autoClose: 8000})
                    window.location.reload()
                    setUsdtAmount("")
                    setToken("")
                } else {
                    toast.error(resBuy.message)
                    setUsdtAmount("")
                    setToken("")
                }
            } else {
                toast.error(approval.message)
                setUsdtAmount("")
                setToken("")
            }

        } catch (error) {
            console.log('Error Buy Usdt', error)
            toast.error('USDT BUY Error', error)
            setUsdtAmount("")
            setToken("")
        } finally {
            hideLoader()
            setUsdtAmount("")
            setToken("")
        }
    }

    const handleClaim = async (e) => {
        e.preventDefault()
        if(!isConnected) {
            toast.error("Please connect with the registered wallet address.");
            setconnectWalletNow(true)
            return
          }
          if(address.toUpperCase() != loginDetails.walletAddress.toUpperCase()) {
            toast.error("Please connect with registered wallet address.")
            return
          }
        showLoader()
        try {
            let request = {
                walletProvider: _useWeb3WalletProvider,
                tokenAddress: form.length > 0 ? form[0].token_address : '',
                preSaleAddress: form.length > 0 ? form[0].presaleContract : '',
                networkId: form.length > 0 ? form[0].chainNetworkId : ''
            }
            let resClaim = await ClaimToken(request)
            if (resClaim.status) {
                toast.success(resClaim.message)
                setTimeout(() => {
                    navigate(`${config.baseUrl}home/`);
                    window.scrollTo(0,0)
                }, 2000);
            } else {
                toast.error(resClaim.message)
            }
        }
        catch (error) {
            console.log('Error Claim', error)
            toast.error('Token Claim Error', error)
        } finally {
            hideLoader()
        }
    }

    const handleRefund = async (e) => {
        e.preventDefault()
        if(!isConnected) {
            toast.error("Please connect with the registered wallet address.");
            setconnectWalletNow(true)
            return
          }
          if(address.toUpperCase() != loginDetails.walletAddress.toUpperCase()) {
            toast.error("Please connect with registered wallet address.")
            return
          }
        showLoader()
        try {
            let request = {
                walletProvider: _useWeb3WalletProvider,
                tokenAddress: form.length > 0 ? form[0].token_address : '',
                preSaleAddress: form.length > 0 ? form[0].presaleContract : '',
                networkId: form.length > 0 ? form[0].chainNetworkId : ''
            }

            let resRefund = await RefundTokens(request)
            if (resRefund.status) {
                toast.success(resRefund.message)
                setTimeout(() => {
                    navigate(`${config.baseUrl}home/`);
                    window.scrollTo(0,0)
                }, 2000);
            } else {
                toast.error(resRefund.message)
            }
        }
        catch (error) {
            console.log('Error Refund', error)
            toast.error('Token Refund Error', error)
        } finally {
            hideLoader()
        }
    }

    const handleListing = async (e, id) => {
        e.preventDefault()
        navigate(`${config.baseUrl}preliquidity/${id}`)
        window.scrollTo(0,0)
    }

    const handleDashboard = async (e) => {
        e.preventDefault()
        navigate(`${config.baseUrl}detaildashboard`)
        window.scrollTo(0,0)
    }

    const chartStyle = {
        maxWidth: '400px',
        padding: '20px',
        background: 'linear-gradient(45deg, #fff, transparent)',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    }

    const [chart, setChart] = useState({
        series: [0, 0, 0, 0, 0],
        options: {
            chart: {
                type: 'donut',
            },
            labels: ['Private Sale', 'ICO', 'Team', 'Partnerships', 'Others'],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: "100%",
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            }]
        }
    })


    return (
        <>
            <Header />
            <Toaster/>
            <div className="detail process_form my-5">
                <div className="infivideo">
                    <video autoPlay muted loop id="myVideo" playsInline >
                        <source src={`${config.baseUrl}images/banner/INFIBGVIDEO.mp4`} type="video/mp4" />
                        Your browser does not support HTML5 video.
                    </video>
                </div>
                <Container>
                    <Row>
                    { form.length > 0 && form[0].walletAddress === address && (
                    <Col lg={12} className="text-end mb-4">
                   
                        <Button variant="dark" className="h-auto btn-sm rounded-5 px-3" onClick={(e) => handleDashboard(e)}>
                        Dashboard&nbsp;<FaArrowRightLong size={`15px`} style={{marginTop:"-3px"}} className="ms-1"/>
                        </Button>
                    
                    </Col>
                    )}
                        {form.length > 0 && (
                            <Col lg={4}>
                                <div className="image-wrapper mb-4 text-md-center">
                                    <img src={`${config.imagePath}${form[0].brand_logo}`} alt="" className="img-fluid" />
                                </div>
                                {form[0].newD && (
                                    <div className="mt-4">
                                        {form[0].walletAddress === address && (<Button variant="dark" className="w-100" onClick={(e) => handleListing(e, id)}
                                            disabled={form[0].usdtBal < form[0].soft_cap}> List your token </Button>)}
                                    </div>
                                )}

                            </Col>)}
                        {form.length > 0 && (
                            <Col lg={8}>

                                <div className="item_info mb-4">
                                    <Row>
                                        <Col lg={5} className="mb-4">
                                            <h2>{form[0].token_name}</h2>
                                            {form[0].description.length > 100 ? 
                                            
                                            <p><ReadMore text={form[0].description} maxLength={100} /></p>:
                                            <p>{form[0].description}</p>
                                        }
                                            <div className="item_info_counts">
                                                <ul className="list-unstyled ps-0">
                                                    <li>
                                                        <div className="item_info_type" >
                                                            <a href={`${form[0].website}`} className="text-decoration-none"> Website</a>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="item_info_type" >
                                                            <a href={`${form[0].whitepaper}`} className="text-decoration-none"> Whitepaper </a>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div>
                                                            <img src={`${config.imagePath}${form[0].chainName}.png`} alt="" width={35} />
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </Col>
                                        <Col lg={7} className="mb-4">
                                            <div id="chart" style={chartStyle}>
                                                <ReactApexChart options={chart.options} series={chart.series} type="donut" />
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="d-inline-block">
                                        <div className="price_attr text-break">
                                            <label className="mb-0 fw-medium">Total Supply </label>
                                            <h5>{form[0].liquidity}</h5>
                                        </div>
                                        <div className="price_attr text-break">
                                            <label className="mb-0 fw-medium">Tokens For Sale</label>
                                            <h5>{form[0].token_amount}</h5>
                                        </div>
                                        <div className="price_attr text-break">
                                            <label className="mb-0 fw-medium">Price</label>
                                            <h5>{form[0].usdt_price} USDT</h5>
                                        </div>
                                        <div className="price_attr text-break">
                                            <label className="mb-0 fw-medium">Start Date</label>
                                            <h5>{new Date(form[0].create_at).toISOString().split('T')[0]}</h5>
                                        </div>
                                        <div className="price_attr text-break">
                                            <label className="mb-0 fw-medium"> Duration </label>
                                            <h5>{form[0].duration} Days</h5>
                                        </div>
                                    </div>
                                </div>
                            </Col>)}
                        <Col lg={12}>
                            <div>
                                <hr />
                            </div>

                        </Col>


                    </Row>
                    <Row className="justify-content-center ">
                        {form.length > 0 && (
                            <Col lg={7}>
                               
                                <Tab.Container id="left-tabs-example" defaultActiveKey="buy" >
                                    <Row>
                                        <Col sm={12} className="tab-style1">
                                            <Nav variant="pills" className="nav-tabs">
                                                {!form[0].newD && (
                                                    <Nav.Item>
                                                        <div className="">
                                                           <h2>Buy</h2>
                                                        </div>
                                                        {/* <Nav.Link eventKey="buy" className="px-4">Buy</Nav.Link> */}
                                                        <div className="Sawpform from_box outlineField px-sm-5 position-relative mt-4 mb-4">
                                                            <div className="buyselect mb-3">
                                                                <Form.Select className="w-auto"
                                                                    onChange={(e) => setBuyOption(e.target.value)}>
                                                                    <option value="1">Buy With USDT</option>
                                                                    {/* <option value="2">Buy With Native</option> */}
                                                                </Form.Select>
                                                            </div>
                                                            {buyOption === '1' && (
                                                                <>
                                                                    <div className=" mb-3"><h2>Buy with USDT</h2>
                                                                    </div>
                                                                    <div>
                                                                        <Row>
                                                                            <Col lg={12}>
                                                                                <Form.Label className="mb-1 small form-label">
                                                                                    <b>USDT Amount</b>
                                                                                    <span className> Balance : {form[0].bal} USDT</span>
                                                                                </Form.Label>
                                                                            </Col>
                                                                            <Col lg={12}>
                                                                                <InputGroup className="mb-4">
                                                                                    <Form.Control
                                                                                        placeholder={""}
                                                                                        aria-describedby="basic-addon2"
                                                                                        name="UsdtAmount"
                                                                                        className="rounded-2 form-control z-1"
                                                                                        style={{ "padding-right": "50px" }}
                                                                                        onChange={onchangehandlerUsdt} />
                                                                                    {/* <Button variant="default" type="button" onClick={setMaxUsdt} className="border-0  h-auto text-blue position-absolute end-0 bg-transparent rounded-2 z-3 cursor-pointer maxbtn">MAX</Button> */}
                                                                                </InputGroup>
                                                                            </Col>

                                                                            {form.length > 0 && (
                                                                                <Col lg={12}>
                                                                                    <div className="mb-3" style={{ "margin-top": "-1px" }}>
                                                                                        <Form.Label className="mb-1 small form-label">{form[0].token_name} Token</Form.Label>
                                                                                        <InputGroup className="mb-3">
                                                                                            <Form.Control 
                                                                                                placeholder=''
                                                                                                aria-describedby="basic-addon2"
                                                                                                name='tokenAmt'
                                                                                                className="form-control"
                                                                                                value={token || ""}
                                                                                                onChange={onchangehandlerUsdt} 
                                                                                                disabled
                                                                                                />
                                                                                        </InputGroup>
                                                                                        {err && <p style={{color:'red'}}>{err}</p>}
                                                                                    </div>
                                                                                </Col>
                                                                            )}
                                                                            {form.length > 0 && (
                                                                                <Col lg={12}>
                                                                                    <div className="mb-3">
                                                                                        <small>Step 1. Approve USDT</small>
                                                                                        <br />
                                                                                        <small>Step 2. Buy {`${form[0].token_name}`} Token</small>
                                                                                    </div>

                                                                                    {!isUsdtApproved && 
                                                                                        <Button variant='primary' className='text-black w-100 fw-medium mb-3' onClick={(e) => handleUsdtBuy(e)} disabled={!UsdtAmount || err} >Buy Now</Button>}

                                                                                    {isUsdtApproved &&
                                                                                        <Button variant='primary' className='text-black w-100 fw-medium mb-3' onClick={(e) => handleUsdtBuy(e)}>Buy Now</Button>}
                                                                                </Col>)}
                                                                        </Row>
                                                                    </div></>
                                                            )}
                                                        </div>
                                                    </Nav.Item>
                                                )}
                                                {form.length > 0 && form[0].newD && (
                                                    <Nav.Item>
                                                        {/* <Nav.Link eventKey="claim" className="px-4">Claim</Nav.Link> */}
                                                        <div className="mt-4 mb-4">
                                                            <Table striped bordered hover responsive>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Token Name </th>
                                                                        <th>Token Address</th>
                                                                        {form[0].usdtBal < form[0].soft_cap ? (
                                                                            <th>USDT</th>
                                                                        ) : (
                                                                            <th>Token Amount</th>
                                                                        )}
                                                                        <th>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>{form[0].token_name}</td>
                                                                        <td>{form[0].token_address}</td>
                                                                        {form[0].usdtBal < form[0].soft_cap ? (
                                                                            <td>{form[0].usdtUser}</td>
                                                                        ) : (
                                                                            <td>{form[0].tokenUser}</td>
                                                                        )}
                                                                        <td>
                                                                            <div>
                                                                                {(form[0].usdtBal < form[0].soft_cap) && form[0].isRefunded ? (
                                                                                    <Button variant="dark" className="btn-sm h-auto" onClick={(e) => handleRefund(e)}  disabled={form[0].usdtBal === 0} >Refund</Button>
                                                                                ) : (
                                                                                    <Button variant="dark" className="me-3 btn-sm h-auto" onClick={(e) => handleClaim(e)} disabled={form[0].tokenUser === 0}>Claim</Button>
                                                                                )}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </Nav.Item>
                                                )}
                                            </Nav>
                                        </Col>
                                    </Row>
                                </Tab.Container>

                            </Col>)}
                    </Row>
                </Container>

            </div>
            <Footer />
        </>
    )
}


//if duration over ==> show list button but disabled.
//if soft cap reached after duration ===> enable list button for owner and show claim page to user. remove buy page
//if soft cap not reached after duration ===> show list button but disabled. show refund page. remove buy page