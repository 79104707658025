import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { GoPlus } from "react-icons/go";
import Footer from "../Footer";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import Modal from 'react-bootstrap/Modal';
import Collapse from 'react-bootstrap/Collapse';
import Web3WalletProvider from "../../../ConnectWallet/walletProvider"
import { getLiquidityFormDetailsById, updateLiquidityTable, submitRemoveLiquidityRequest } from "../../../Action/action"
import { PoolDetails, LPBalance, PoolBalances, ReceivableAmountAfterRemovingLiquidity, ApproveUsdtToken, ApproveToken, RemovalStatus, getTokenPriceSwap, UsdtAmount, LpToMint, AddLiquidity, RemoveLiquidity } from "../../../Web3/Liquidity/swapConnector"
import toast, { Toaster } from "react-hot-toast";
import { error } from "ajv/dist/vocabularies/applicator/dependencies";
import config from "../../../config";
import "../../Detail/detail.css"
import { showLoader,hideLoader } from "../../loader";
import { useDisconnect, useAccount } from "wagmi";


export default function Liquidity() {
    const { id } = useParams()
    const [form, setForm] = useState([]);
    const _useWeb3WalletProvider = Web3WalletProvider()
    const walletConnnected = useSelector((state) => state.auth.walletConnnected);
    const loginDetails = useSelector((state) => state.auth.LoginDetails);
    const [connectWalletNow, setConnectWalletNow] = useState(false);
    const [WalletAddress, setWalletAddress] = useState("")
    const [isProviderLoaded, setisProviderLoaded] = useState(false);
    const navigate = useNavigate();
    const [IsProcessing, setIsProcessing] = useState(false);
    const [PageLoading, setPageLoading] = useState(false);
    const {address, isConnected} = useAccount()

    const [lpAmt, setLpAmt] = useState(0)
    const [tokenAmt, setTokenAmt] = useState(0)
    const [usdtDeduct, setUsdtDeduct] = useState(0)

    const [show, setShow] = useState(false);

    const [remove, setRemove] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleShow2 = () => setRemove(true);
    const handleClose2 = () => setRemove(false);




    const [open, setOpen] = useState(false);

    useEffect(() => {
        setWalletAddress(walletConnnected)
    }, [walletConnnected])

    useEffect(() => {
        if (!isProviderLoaded) {
            _useWeb3WalletProvider.then(data => {
                if (data) {
                    setisProviderLoaded(true)
                }
            })
        }
    }, [_useWeb3WalletProvider])

    useEffect(() => {
        if(isProviderLoaded) {
        getFormData()
        }
    }, [isProviderLoaded])

    const getFormData = async () => {
        try {
            const response = await getLiquidityFormDetailsById({ id });
            console.log('FORM', response)
            if (response.success) {
                if (response.data.length > 0) {
                    let pb = await PoolBalances(response.data)
                    response.data[0].BALANCES = pb.data[0]

                    let lpb = await lpBal(response.data[0])
                    response.data[0].LPBAL = lpb

                    let uAmt = await usdtToDeduct(response.data[0], 0)
                    response.data[0].UAMT = uAmt

                    let tp = await getTokenPriceSwap(response.data)
                    response.data[0].PRICE = tp.data[0]

                    let remove = await removeStat(response.data[0])
                    response.data[0].canRemove = remove

                    let expectedAmt = await removeLiquidityExpectedAmount(response.data[0], 0)
                    response.data[0].EXPECTED = expectedAmt
                }
                setForm(response.data);
                //console.log('FORM2',form)
            }
        } catch (error) {
            console.log(error);
        }
    };


    const poolDetails = async (formData) => {
        let request = {
            walletProvider: _useWeb3WalletProvider,
            tokenAddress: formData.token_address,
            pairContractAddress: formData.pairContractAddress,
            networkId: formData.chainNetworkId,
        }
        const res = await PoolDetails(request)

        return (res?.Bal || 0)
    }

    const poolTokenBalance = async (formData) => {
        let request = {
            walletProvider: _useWeb3WalletProvider,
            tokenAddress: formData.token_address,
            pairContractAddress: formData.pairContractAddress,
            networkId: formData.chainNetworkId,
        }
        const res = await PoolBalances(request)
        if (res.length > 0) {
            return (res[0].data)
        }
    }

    const removeLiquidityExpectedAmount = async (formData, _lp) => {
        let request = {
            walletProvider: _useWeb3WalletProvider,
            tokenAddress: formData.token_address,
            pairContractAddress: formData.pairContractAddress,
            lpAmount: _lp,
            networkId: formData.chainNetworkId,
        }
        const res = await ReceivableAmountAfterRemovingLiquidity(request)
        if (res.length > 0) {
            return (res[0].data)
        }
    }

    const lpBal = async (formData) => {
        let request = {
            walletProvider: _useWeb3WalletProvider,
            tokenAddress: formData.token_address,
            pairContractAddress: formData.pairContractAddress,
            networkId: formData.chainNetworkId,
        }
        const res = await LPBalance(request)
        console.log('RESS', res)
        return res.balance
    }

    const usdtToDeduct = async (formData, _tokenAmt) => {
        let request = {
            walletProvider: _useWeb3WalletProvider,
            tokenAddress: formData.token_address,
            tokenAmount: _tokenAmt,
            pairContractAddress: formData.pairContractAddress,
            networkId: formData.chainNetworkId,
        }
        const res = await UsdtAmount(request)
        return res.Bal
    }

    const TokenPrice = async (formData) => {
        try {
            let request = {
                walletProvider: _useWeb3WalletProvider,
                tokenName: formData.token_name,
                pairContractAddress: formData.pairContractAddress,
                tokenAddress: formData.token_address,
                networkId: formData.chainNetworkId
            }

            let price = await getTokenPriceSwap(request)
            console.log('PPP', price)
            return price.price

        } catch (error) {
            console.log(error)
        }
    }

    const lpMintAmt = async (formData) => {
        let request = {
            walletProvider: _useWeb3WalletProvider,
            tokenAddress: formData.token_address,
            tokenAmount: tokenAmt,
            pairContractAddress: formData.pairContractAddress,
            usdtAddress: formData.usdtContract,
            UsdtAmount: usdtDeduct,
            networkId: formData.chainNetworkId,
        }
        const res = await LpToMint(request)
        return (res?.Bal || 0)
    }


    const handleAddLiquidity = async (e) => {
        e.preventDefault()
        if(!isConnected) {
            toast.error("Please connect with the registered wallet address.");
            return
          }
          if(address.toUpperCase() != loginDetails.walletAddress.toUpperCase()) {
            toast.error("Please connect with registered wallet address.")
            return
          }
        showLoader()
        try {
            let request = {
                walletProvider: _useWeb3WalletProvider,
                tokenAddress: form.length > 0 ? form[0].token_address : '',
                tokenAmount: tokenAmt,
                pairContractAddress: form.length > 0 ? form[0].pairContractAddress : '',
                usdtAddress: form.length > 0 ? form[0].usdtContract : '',
                usdtAmount: form[0].UAMT,
                networkId: form.length > 0 ? form[0].chainNetworkId : '',
            }
            console.log('ADDD', request)
            let usdtApproval = await ApproveUsdtToken(request)
            if (usdtApproval.status) {
                let tokenApproval = await ApproveToken(request)
                if (tokenApproval.status) {
                    let resAdd = await AddLiquidity(request)
                    console.log('add liquid', resAdd)
                    if (resAdd.status) {
                        toast.success(resAdd.message)
                        setTokenAmt("")
                        setUsdtDeduct("")
                        let data = {
                            usdt_amount: request.usdtAmount,
                            liquidity: request.tokenAmount
                        }
                        console.log("DB", data)
                        let res = await updateLiquidityTable(data, loginDetails)
                        if (res.success) {
                            toast.success(res.msg);
                        } else {
                            console.log('Error updating table', error)
                            toast.error('Error updating table', error)
                        }
                    } else {
                        console.log('Add liquidty error', error)
                        toast.error(resAdd.message)
                    }
                } else {
                    console.log('Token liquidity approval error', error)
                    toast.error(tokenApproval.message)
                }
            } else {
                console.log('usdt liquidity approval error', error)
                toast.error(usdtApproval.message)
            }
        } catch (error) {
            console.log(error)
        } finally {
            hideLoader()
        }
    }

    const requestRemoveLiquidity = async () => {
        let data = {
            owner_id: form[0].owner_id,
            owner_address: form[0].walletAddress,
            pair_name: form[0].pair_name,
            token_address: form[0].token_address,
            chain: form[0].chain
        }
        let res = await submitRemoveLiquidityRequest(data, loginDetails)
        console.log('RDB', res)
        if (res.success) {
            toast.success(res.msg)
        } else {
            toast.error(res.msg)
        }
        console.log("requestRemove function called!");
    };


    const removeStat = async (formData) => {
        try {
            let request = {
                walletProvider: _useWeb3WalletProvider,
                tokenAddress: formData.token_address,
                pairContractAddress: formData.pairContractAddress,
                networkId: formData.chainNetworkId
            }
            let status = await RemovalStatus(request)
            console.log('remove?', status)
            return status
        } catch (error) {
            console.log(error)
        }
    }


    const handleRemoveLiquidity = async (e) => {
        e.preventDefault()
        if(!isConnected) {
            toast.error("Please connect with the registered wallet address.");
            return
          }
          if(address.toUpperCase() != loginDetails.walletAddress.toUpperCase()) {
            toast.error("Please connect with registered wallet address")
            return
          }
        showLoader()
        try {
            let request = {
                walletProvider: _useWeb3WalletProvider,
                tokenAddress: form.length > 0 ? form[0].token_address : '',
                pairContractAddress: form.length > 0 ? form[0].pairContractAddress : '',
                lpAmount: lpAmt,
                networkId: form.length > 0 ? form[0].chainNetworkId : '',
            }
            console.log("R",request.usdtAmount)
            let resRemove = await RemoveLiquidity(request)
            if (resRemove.status) {
                toast.success(resRemove.message)
                setLpAmt("")
                //window.location.reload()
                //await updateDb()
            } else {
                console.log('Remove liquidty error', error)
                toast.error(resRemove.message)
                setLpAmt("")
            }
        } catch (error) {
            console.log(error)
        } finally {
            hideLoader()
        }
    }
   

    const inputhandler = async (e) => {
        e.preventDefault()
        let newToken = e.target.value
        setTokenAmt(newToken)
        let updatedForm = [...form]
        let res = await usdtToDeduct(updatedForm[0], newToken)
        updatedForm[0].UAMT = res
        setForm(updatedForm)
    }

    const inputhandler2 = async (e) => {
        e.preventDefault()
        let newLP = e.target.value
        setLpAmt(newLP)
        let newForm = [...form]
        let res2 = await removeLiquidityExpectedAmount(newForm[0], newLP)
        newForm[0].EXPECTED = res2
        setForm(newForm)
    }




    return (
        <>
        <Toaster/>
            <section>
                <div className="d-flex justify-content-between mt-3">
                    <div className="d-flex align-items-center">
                        <h4 className="mb-0">Your Liquidity</h4>
                    </div>
                </div>
                {
                    true
                        ?
                        <div className="mt-4">
                            <div className="wrapper">
                                <div className="d-flex justify-content-between">
                                    <div>
                                        {form.length > 0 &&
                                            <div className="d-flex align-items-center">
                                                <div className="me-2" style={{ lineHeight: "1.4" }}>
                                                    <div className="fw-bold ">Name : {form[0].pair_name} <img src={`${config.imagePath}${form[0].chainName}.png`} alt="" width={18} className="ms-1" style={{ marginTop: "-4px" }} /></div>
                                                    <div className="fw-bold">Price : 1 {form[0].token_name} = <span className="fw-medium">{form[0].PRICE} USDT</span> </div>
                                                </div>
                                            </div>}
                                    </div>


                                    <div onClick={() => setOpen(!open)}>
                                        <div className="h-100 d-flex align-items-center justify-content-end"
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <div style={{ color: 'var(--infi-bg)' }} className="d-flex justify-content-end fw-bold">
                                                <span>Manage</span>
                                                <span> <IoIosArrowDown /> </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {form.length > 0 &&
                                    <Collapse in={open}>
                                        <div className="mt-3">
                                            <div className="d-flex justify-content-between">
                                                <div className="fw-bold">Total LP tokens</div>
                                                <div>{form[0].LPBAL}</div>
                                            </div>

                                            <div className="d-flex justify-content-between mt-3">
                                                <div className="fw-bold">Pooled {form[0].token_name}</div>
                                                <div className="d-flex align-items-center">
                                                    <span className="me-1">
                                                        {form[0].BALANCES.Token}
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="d-flex justify-content-between mt-3">
                                                <div className="fw-bold">Pooled USDT</div>
                                                <div className="d-flex align-items-center">
                                                    <span className="me-1">
                                                        {form[0].BALANCES.USDT}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </Collapse>}
                                <div >
                                    <Row>
                                        <Col xl={6} className="mt-3">
                                            <Button variant="primary" className="w-100 h-100" onClick={handleShow}>Add</Button>
                                        </Col>

                                        <Col xl={6} className="text-end mt-3">
                                            {form.length > 0 && !form[0].canRemove.status ? (
                                            <Button variant="primary" className="me-3" onClick={requestRemoveLiquidity}> Request To Remove Liquidity </Button>
                                            ) : (
                                            <Button variant="dark" className="text-uppercase" onClick={handleShow2}> Remove Liquidity </Button>
                                            )}
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="text-center mt-5">
                                <div>
                                    <img src="/images/Search.png" alt="No Data Found" className="img-fluid" width={150} />
                                </div>

                                <div className="fs-3 fw-bold mt-3">No Liquidity found</div>
                            </div>
                        </div>
                }
                <div className='mt-5'>
                    <Footer />
                </div>
            </section>

            {/* ====================================== Modal Start ================================= */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <b>Add Liquidity</b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-2">
                        {form.length > 0 &&
                            <Row>
                                <Col lg={12}>
                                    <Form.Group className="mb-2">
                                        <Form.Control
                                            placeholder="Enter token amount"
                                            name="tokenamount"
                                            className="small"
                                            value={tokenAmt || ""}
                                            onChange={inputhandler}
                                        />
                                    </Form.Group>
                                    <Form.Group >
                                        <Form.Control
                                            placeholder="USDT Amount"
                                            name="USDT"
                                            className="small"
                                            value={form[0].UAMT || ""}
                                            disabled
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>}
                    </div>
                    {/* {form.length > 0 && 
                    <div className="d-flex justify-content-between">
                        <div className="">New Price</div>
                        <div className="">8</div>
                    </div> } */}
                    <div className="mt-4">
                        <Button variant="primary" className="w-100" onClick={(e) => {
                            handleAddLiquidity(e)
                        }}>Confirm Supply  </Button>
                    </div>
                </Modal.Body>
            </Modal>
            {/* ====================================== Modal Remove Liquidity Start ================================= */}
            <Modal show={remove} onHide={handleClose2}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <b></b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-2">
                        <Row>
                            <Col lg={12}>
                                <Form.Group>
                                    <Form.Control
                                        placeholder="Enter LP Token"
                                        name="lptoken"
                                        className="small"
                                        value={lpAmt || ""}
                                        onChange={inputhandler2}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>

                    {form.length > 0 && (
                        <div>
                            <div
                                className="d-flex justify-content-between">
                                <div className="fw-bold">Your LP Balance</div>
                                <div className="">{form[0].LPBAL}</div>
                            </div>
                            <div className="border mt-2 rounded-2 overflow-hidden"> 
                                <div className="text-center py-2 bg-light border-bottom">
                                    <h6 className="fw-bold mb-0">You Will Receive </h6>

                                </div>

                                <div
                                    className="d-flex justify-content-between my-2 px-2">
                                    <div className="fw-bold">{form[0].token_name}</div>
                                    <div className="">{form[0].EXPECTED.Token}</div>
                                </div>
                                <div className="d-flex justify-content-between my-2 px-2">
                                    <div className="fw-bold">USDT</div>
                                    <div className="">{form[0].EXPECTED.USDT}</div>
                                </div>
                                <div className="d-flex justify-content-between my-2 px-2">
                                    <div className="fw-bold">PENALTY</div>
                                    <div className="">{form[0].EXPECTED.PENALTY}</div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="mt-4">
                        <Button variant="primary" className="w-100" onClick={(e) => {
                            handleRemoveLiquidity(e)
                        }}>Confirm </Button>
                    </div>
                </Modal.Body>
            </Modal>
                   </>
    )
}