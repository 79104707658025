import {
  getRequest,
  postRequest,
  postRequestFormData,
  postrequestFormDataForImage,
} from '../config/helper';
export const registerAction = async (data, loginData) => {
  return postRequest('registration', data, loginData).then((res) => {
    return res.data;
  });
};
export const loginAction = async (data, loginData) => {
  return postRequest('login', data, loginData).then((res) => {
    return res.data;
  });
};

export const changePasswordAction = async (data) => {
  return await postRequest('forgotPassword', data).then((res) => {
    return res.data;
  });
};

export const changeAddressAction = async (data) => {
  return await postRequest('forgotAddress', data).then((res) => {
    return res.data;
  });
};

export const verifyEmail = async (data) => {
  return await postRequest('verifyEmail', data).then((res) => {
    return res.data;
  });
};

export const verifyEmail2 = async (data) => {
  return await postRequest('verifyEmail2', data).then((res) => {
    return res.data;
  });
};

export const verifyLink = async (data) => {
  return await postRequest('verify', data).then((res) => {
    return res.data;
  });
};

export const getActiveChains = async (data, loginData) => {
  return getRequest('getActiveChains', data, loginData).then((res) => {
    return res.data;
  });
};

export const getFormDetailsById = async (data, loginData) => {
  return getRequest('getFormDetailsById', data, loginData).then((res) => {
    return res.data;
  });
};

export const getAllPresaleListedByAddress = async (data, loginData) => {
  return postRequest('getAllPresaleListed', data, loginData).then((res) => {
    return res.data;
  });
};

export const getAllPairListed = async (data, loginData) => {
  return postRequest('getAllPairListed', data, loginData).then((res) => {
    return res.data;
  });
};

export const submitWithdrawRequest = async (data, loginData) => {
  return postRequest('submitWithdrawRequest', data, loginData).then((res) => {
    return res.data;
  });
};

export const submitRemoveLiquidityRequest = async (data, loginData) => {
  return postRequest('submitRemoveLiquidityRequest', data, loginData).then(
    (res) => {
      return res.data;
    }
  );
};

export const updateApplicationFrom = async (data, loginData) => {
  return postRequestFormData('updateApplicationFrom', data, loginData).then(
    (res) => {
      return res.data;
    }
  );
};

export const submitApplicationFrom = async (data, loginData) => {
  return postRequestFormData('submitApplicationFrom', data, loginData).then(
    (res) => {
      return res.data;
    }
  );
};

export const getUpcommingApplicationFroms = async (data, loginData) => {
  return getRequest('getUpcommingApplicationFroms', data, loginData).then(
    (res) => {
      return res.data;
    }
  );
};

export const changeListingStatus = async (data, loginData) => {
  return postRequest('changeListingStatus', data, loginData).then((res) => {
    return res.data;
  });
};

export const getListedApplicationFroms = async (data, loginData) => {
  return getRequest('getListedApplicationFroms', data, loginData).then(
    (res) => {
      return res.data;
    }
  );
};

// export const getBlockNumber = async () => {
//   return getRequest('blockNumber').then((res) => {
//     return res.data;
//   });
// };

// export const updateBlock = async (data, loginData) => {
//   return postRequest('updateBlock', data, loginData).then((res) => {
//     return res.data;
//   });
// };

// export const formDataAPI = async (data, loginData) => {
//   return getRequest('getForm', data, loginData).then((res) => {
//     return res.data;
//   });
// };

export const getChainId = async (data, loginData) => {
  return getRequest('getChainById', data, loginData).then((res) => {
    return res.data;
  });
};

{
  /* ============== Liquidity ============ */
}

export const getLiquidityFormDetailsById = async (data, loginData) => {
  return getRequest('getLiquidityFormDetailsById', data, loginData).then(
    (res) => {
      return res.data;
    }
  );
};

export const updateLiquidityApplicationForm = async (data, loginData) => {
  return postRequestFormData(
    'updateLiquidityApplicationForm',
    data,
    loginData
  ).then((res) => {
    return res.data;
  });
};

export const submitLiquidityApplicationForm = async (data, loginData) => {
  return postRequestFormData(
    'submitLiquidityApplicationForm',
    data,
    loginData
  ).then((res) => {
    return res.data;
  });
};

export const changeLiquidityListingStatus = async (data, loginData) => {
  return postRequest('changeLiquidityListingStatus', data, loginData).then(
    (res) => {
      return res.data;
    }
  );
};

export const updateLiquidityTable = async (data, loginData) => {
  return postRequestFormData(
    'updateAfterLiquidityChange',
    data,
    loginData
  ).then((res) => {
    return res.data;
  });
};

export const getInfiFormDetailsById = async (data, loginData) => {
  return getRequest('getInfiFormDetailsById', data, loginData).then((res) => {
    return res.data;
  });
};

export const submitInfiApplicationForm = async (data, loginData) => {
  return postRequestFormData('submitInfiApplicationForm', data, loginData).then(
    (res) => {
      return res.data;
    }
  );
};

export const changeInfiListingStatus = async (data, loginData) => {
  return postRequest('changeInfiListingStatus', data, loginData).then((res) => {
    return res.data;
  });
};

export const updateInfiTable = async (data, loginData) => {
  return postRequestFormData(
    'updateAfterInfiLiquidityChange',
    data,
    loginData
  ).then((res) => {
    return res.data;
  });
};

{
  /* ============== Shared ============ */
}

export const getAllUpcommingApplicationForms = async (data, loginData) => {
  return getRequest('getAllUpcommingApplicationForms', data, loginData).then(
    (res) => {
      return res.data;
    }
  );
};

export const getAllListedApplicationForms = async (data, loginData) => {
  return getRequest('getAllListedApplicationForms', data, loginData).then(
    (res) => {
      return res.data;
    }
  );
};

export const submitContactUsForm = async (data, loginData) => {
  return postRequest('submitContactUsForm', data, loginData).then((res) => {
    return res.data;
  });
};
