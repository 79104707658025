import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import web3config from './Web3/web3config';
import {
  polygon,
  mainnet,
  avalanche,
  arbitrum,
  bsc,
  base,
  polygonAmoy,
  sepolia,
  avalancheFuji,
  arbitrumSepolia,
  bscTestnet,
  baseSepolia,
} from 'wagmi/chains';
import store, { persistor } from './redux/storeConfig';
import { Provider } from 'react-redux';

import { PersistGate } from 'redux-persist/integration/react';
import './style/css/index';
import { BrowserRouter } from 'react-router-dom';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createWeb3Modal } from '@web3modal/wagmi/react';
import { http, createConfig, WagmiProvider } from 'wagmi';
import {
  walletConnect,
  injected,
  coinbaseWallet,
  metaMask,
} from 'wagmi/connectors';

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root'));
const projectId = web3config.PROJECTID;

const metadata = {
  name: 'Web3Modal',
  description: 'INFI MultiChain Launchpad',
  url: 'http://localhost:3000',
  icons: ['https://avatars.githubusercontent.com/u/37784886'],
};

const config = createConfig({
  chains: [
    polygon,
    mainnet,
    avalanche,
    arbitrum,
    bsc,
    base,
    polygonAmoy,
    sepolia,
    avalancheFuji,
    arbitrumSepolia,
    bscTestnet,
    baseSepolia,
  ],
  transports: {
    [polygon.id]: http(),
    [mainnet.id]: http(),
    [avalanche.id]: http(),
    [arbitrum.id]: http(),
    [bsc.id]: http(),
    [base.id]: http(),
    [polygonAmoy.id]: http(),
    [sepolia.id]: http(),
    [avalancheFuji.id]: http(),
    [arbitrumSepolia.id]: http(),
    [bscTestnet.id]: http(),
    [baseSepolia.id]: http(),
  },
  connectors: [
    //  metaMask({ shimDisconnect: true }),
    walletConnect({ projectId, metadata, showQrModal: false }),
    injected({ shimDisconnect: true }),
    coinbaseWallet({
      appName: metadata.name,
      appLogoUrl: metadata.icons[0],
    }),
  ],
});

createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: true,
  enableOnramp: true,
  disableLookup: true,
  //defaultChain: polygon,
});

root.render(
  <>
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
      </QueryClientProvider>
    </WagmiProvider>
  </>
);

reportWebVitals();
