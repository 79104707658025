import React from 'react';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Accordion } from 'react-bootstrap';
import "./faq.css"
import config from '../../config';
export default function Faq() {
  const loginDetails = useSelector((state) => state.auth.LoginDetails);
  const navigate = useNavigate();

  if (!loginDetails) {
    navigate(`${config.baseUrl}`);
    window.scrollTo(0,0)
  }
  return (
    <>
      <Header />
      <section className='faq py-4'>
        <Container>
          <Row>
            <Col lg={12}>
              <div>
                <h1>Frequently Asked Questions (FAQS)</h1>
                <div className='accordion-style mt-4'>
                  <Accordion defaultActiveKey={['0']}>
                    <Accordion.Item eventKey="0" className='mb-3'>
                      <Accordion.Header>What is the ©SbSe Protocol? </Accordion.Header>
                      <Accordion.Body>
                        The ©SbSe protocol is a groundbreaking advancement in blockchain, tokenisation and
                        cryptocurrency. It independently monitors the ecosystem, improves transaction security and
                        speed, rewards users and promotes transparency. Key features include a unique liquidity
                        management system with a 48-hour compensation mechanism to deter fraud, and monitoring of
                        trading on the INFI CDEX MultiChain Exchange to mitigate volatility and manipulation.

                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1" className='mb-3'>
                      <Accordion.Header>How are the trading fees and rewards calculated on the Exchange? </Accordion.Header>
                      <Accordion.Body>
                        This function is automatic. It is controlled by special formulas embedded in the ©SbSe protocol,
                        eliminating the need for human intervention.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2" className='mb-3'>
                      <Accordion.Header>Can I buy and sell as much I want in one trading?
                      </Accordion.Header>
                      <Accordion.Body>
                        No. Anti-price manipulation, price volatility are protected by a variety of specialised formulas built
                        into the protocol. This functionality tracks trading volumes on the INFI CDEX exchange and
                        notifies traders of the maximum and minimum trading allocations allowed.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3" className='mb-3'>
                      <Accordion.Header>Can I proceed with launching my project without agreeing to the Special Liquidity
                        Management (SpecialLM) on the INFI Launchpad? Why is this requirement necessary?</Accordion.Header>
                      <Accordion.Body>
                        No, you can't launch or list without SpecialLM. Before you launch or list a project on INFI
                        Launchpad, you must agree with SpecialLM feature. This option gives investors peace of mind for
                        their investment by offering them a 48 hour compensation scheme in case of fraud.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4" className='mb-3'>
                      <Accordion.Header>Do project owners need KYC or audit to start on INFI MultiChain CDEX Exchange?</Accordion.Header>
                      <Accordion.Body>
                        No, they don't have to! That's why we've developed SpecialLM.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5" className='mb-3'>
                      <Accordion.Header>After each transaction, how much Stable Coins will I receive?</Accordion.Header>
                      <Accordion.Body>
                        The ©SbSe Protocol has been designed to provide the trader with a cash back of 20% of the final
                        fee in stable coins after each transaction.
                      </Accordion.Body>
                    </Accordion.Item>


                    <Accordion.Item eventKey="6" className='mb-3'>
                      <Accordion.Header>In which crypto currency are the fees paid? Do I need to keep extra money in my wallet to
                        cover these fees? Where those fees lands?</Accordion.Header>
                      <Accordion.Body>
                        Fees are automatically calculated by the ©SbSe protocol in Stable Coins / USDT only and are
                        deducted from the trading allocation. You don't need to hold extra Stable Coins in your wallet to
                        cover the fees. All fees will be placed in the SbSe PFP, visible to all.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7" className='mb-3'>
                      <Accordion.Header>What is INFI? How can I benefit from the company's profits and how can I calculate my
                        earnings? What are the minimum and maximum amounts of INFI that I can hold in a single
                        wallet? </Accordion.Header>
                      <Accordion.Body>
                        INFI serves as the primary token, which goes beyond the realm of pure cryptocurrency.
                        Ownership of INFI entitles the holder to a share in the parent company, Inverted Investment, as
                        well as eligibility for a monthly passive income of 60% derived from the SbSe PFP distributed in
                        stable coin and Bitcoin from the SbSe BTC. INFI has a unique staking period of 24 months. The
                        minimum allocation for staking INFI is set at 10 INFI, with a maximum limit of 12,000 INFI per
                        wallet. For allocations over 12.000 INFI we recommend additional wallet. Profits will be calculated
                        automatically by the protocol.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="8" className='mb-3'>
                      <Accordion.Header>Is INFI MultiChain CDEX Exchange centralized or decentralized? </Accordion.Header>
                      <Accordion.Body>
                        INFI MultiChain CDEX Exchange is decentralized.
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="9" className='mb-3'>
                      <Accordion.Header>What happens with my staked INFI earned during my trading period? Can I un-stake them? </Accordion.Header>
                      <Accordion.Body>
                        The staked INFI earned during the trading period can be merged only if the trader has before
                        staked INFI (you need to buy and stake to be able to merge the staked INFI earned during the
                        trading period). Unstaking before the end of the 24 month staking period will result in a 50% loss
                        of the total staked INFI allocation.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="10" className='mb-3'>
                      <Accordion.Header>What happens after the staking period ends? Can I re-stake them?</Accordion.Header>
                      <Accordion.Body>
                        Prior to the end of the staking period, the INFI holder will receive an offer in $$$ from the
                        Company in the event that they wish to re-stake their INFI allocation for a further 24 month period.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="11" className='mb-3'>
                      <Accordion.Header>Will be INFI listed on any other DEX or CEX platform?</Accordion.Header>
                      <Accordion.Body>
                        No. INFI will be listed exclusively only on INFI MultiChain CDEX Exchange.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="12" className='mb-3'>
                      <Accordion.Header>Will Core team member call me or ask for my wallet key or INFI allocation ?</Accordion.Header>
                      <Accordion.Body>
                        NO. If anyone contacts you claiming to be from the team it is a SCAM and you should report it
                        immediately to: <a href='#'>support@infimultichain.com</a> ! Never give out sensitive information to anyone!

                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="13" className='mb-3'>
                      <Accordion.Header>How to reach the core team? </Accordion.Header>
                      <Accordion.Body>
                          We are always here to help. Please do not hesitate to contact us by email.
                          <div>
                            <h6>Our official email addresses:</h6>
                            <ul className='list-unstyled'>
                              <li><a href="#">info@invertedinvestment.com</a></li>
                              <li><a href="#">support@infimultichain.com</a></li>
                            </ul>
                          </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                </div>
              </div>
            </Col>
          </Row>
        </Container>

      </section>
      <Footer />
    </>
  );
}
