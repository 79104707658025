import React, { useState, useEffect } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { IoSearch } from "react-icons/io5";
import InputGroup from 'react-bootstrap/InputGroup';
import { JackInTheBox, Slide, Zoom } from "react-reveal";
import { getAllUpcommingApplicationForms, getAllListedApplicationForms } from "../../Action/action"
import { TokenSaleDuration ,TokenPriceUsdt,PresaleTokenSupply, getTokenSupplyPresale} from "../../Web3/Connector";
import { getTokenPriceInfiSwap, getTokenSupplyInfiSwap} from "../../Web3/Liquidity/infiSwapConnector"
import {getTokenPriceSwap,getTokenSupplySwap} from "../../Web3/Liquidity/swapConnector"
import config from "../../config";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./css/projectlist.css"
import { error } from "ajv/dist/vocabularies/applicator/dependencies";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from 'react-redux';
import { useDisconnect, useAccount } from "wagmi";
import Web3WalletProvider from "../../ConnectWallet/walletProvider"


export default function ProjectList() {
    const navigate = useNavigate();
    //console.log('LOGO',loginDetails)
    const loginDetails = useSelector((state) => state.auth.LoginDetails);
    const { address, isConnected } = useAccount()
    const [upcommings, setUpcommings] = useState([])
    const [listed, setListed] = useState({
        presaleApplications:[],
        liquidityApplications:[],
        infiLiquidityApplications:[]
    })
    const [search, setSearch] = useState("")
    const [isLoading, setIsLoading] = useState(true)
    const [isProviderLoaded, setisProviderLoaded] = useState(false);
    const _useWeb3WalletProvider = Web3WalletProvider()


    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false)
        }, 5000)
  
        return () => clearTimeout(timer)
    },[])

    // useEffect(() => {
    //     if (!isProviderLoaded) {
    //         _useWeb3WalletProvider.then((data) => {
    //             if (data) {
    //                 setisProviderLoaded(true)
    //             }
    //         })
    //     }
    // }, [_useWeb3WalletProvider])

    useEffect(() => {
        getListingData()
        getUpcomingData()
    }, [])



    const getListingData = async () => {
        try {
            let list = await getAllListedApplicationForms({},loginDetails);
            console.log('LISTED', list);
    
            if (list.success) {
                let res1, res2, res3, res4, res5, res6,d;
    
                if (list.data.presaleApplications.length > 0) {
                    [res1, res2,d] = await Promise.all([
                        getTokenSupplyPresale(list.data.presaleApplications),
                        TokenPriceUsdt(list.data.presaleApplications),
                        TokenSaleDuration(list.data.presaleApplications)
                    ]);
                    console.log("Presale Results", res1, res2);
                }
    
                if (list.data.liquidityApplications.length > 0) {
                    [res3, res4] = await Promise.all([
                        getTokenSupplySwap(list.data.liquidityApplications),
                        getTokenPriceSwap(list.data.liquidityApplications)
                    ]);
                    console.log("Liquidity Results", res3, res4);
                }
    
                if (list.data.infiLiquidityApplications.length > 0) {
                    [res5, res6] = await Promise.all([
                        getTokenSupplyInfiSwap(list.data.infiLiquidityApplications),
                        getTokenPriceInfiSwap(list.data.infiLiquidityApplications)
                    ]);
                    console.log("InfiLiquidity Results", res5, res6);
                }
    
                for (let i = 0; i < list.data.presaleApplications.length; i++) {
                    //const result = await checkDuration(list.data.presaleApplications[i]);
                    //console.log("DD",result)
                    list.data.presaleApplications[i].duration = d.duration[i] || ""
                    list.data.presaleApplications[i].TOKEN_PRICE_PRESALE = res2?.data[i] || "";
                    list.data.presaleApplications[i].PRE_TOTAL = res1?.data[i] || ""
                }
    
                for (let i = 0; i < list.data.liquidityApplications.length; i++) {
                    //const result = await checkDuration(list.data.liquidityApplications[i]);
                   // console.log("DD",result)
                    //list.data.liquidityApplications[i].duration = result;
                    list.data.liquidityApplications[i].TOKEN_PRICE_SWAP = res4?.data[i] || "";
                    list.data.liquidityApplications[i].SWAP_TOTAL = res3?.data[i] || ""
                }
    
                for (let i = 0; i < list.data.infiLiquidityApplications.length; i++) {
                    //const result = await checkDuration(list.data.infiLiquidityApplications[i]);
                    //list.data.infiLiquidityApplications[i].duration = result;
                    list.data.infiLiquidityApplications[i].TOKEN_PRICE_INFI_SWAP = res6?.data[i] || "";
                    list.data.infiLiquidityApplications[i].INFI_TOTAL = res5?.data[i] || ""

                }
    
                setListed(list.data);
                console.log('ll2', list);
            } else {
                toast.error("Error getting project list");
                console.log('Error getting project list', error);
            }
        } catch (error) {
            console.log(error);
        }
    };
    
    

    const getUpcomingData = async () => {
        try {
            let up = await getAllUpcommingApplicationForms({}, loginDetails)
            console.log('upcoming', up)
            if (up.success) {
                if (up.data.length > 0) {
                    setUpcommings(up.data)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }


    // const checkDuration = async (lists) => {
    //     let request = {
    //         walletProvider: lists.chainRPCUrl,
    //         tokenAddress: await lists.token_address,
    //         preSaleAddress: await lists.presaleContract,
    //         networkId: await lists.chainNetworkId
    //     }
    //     let endTime = await TokenSaleDuration(request)
    //     let currentTime = Math.floor(Date.now() / 1000);
    //     console.log('TIME', (Number(endTime.duration)), currentTime)
    //     //setDuration(currentTime > Number(endTime.duration))
    //     return currentTime > Number(endTime.duration)
    // }

    //Presale
    const sendToListingForm = async (e, id) => {
        e.preventDefault()
        navigate(`${config.baseUrl}launchListingForm/${id}`)
        window.scrollTo(0,0)
    }

    const openBuyModule = async (e, id) => {
        e.preventDefault()
        if (Object.keys(loginDetails).length === 0) {
            navigate(`${config.baseUrl}login`);
            window.scrollTo(0,0)
        } else {
            if (!isConnected) {
                toast.error("Please connect with the registered wallet address.");
                return
            }
          else if (address.toUpperCase() != loginDetails.walletAddress.toUpperCase()) {
                toast.error("Please connect with registered wallet address.")
                return
            }
            else {
                navigate(`${config.baseUrl}detail/${id}`)
                window.scrollTo(0,0)
            }
            
        }
    }

    //Swap

    const sendToLiquidityListingForm = async (e, id) => {
        e.preventDefault()
        navigate(`${config.baseUrl}liquidityLaunchForm/${id}`)
        window.scrollTo(0,0)
    }



    const sendToInfiListingForm = async (e, id) => {
        e.preventDefault()
        navigate(`${config.baseUrl}infiLaunchForm/${id}`)
        window.scrollTo(0,0)
    }

    const openSwapModule = async (e, id) => {
        e.preventDefault()
        if (Object.keys(loginDetails).length === 0) {
            navigate(`${config.baseUrl}login`);
            window.scrollTo(0,0)
        } else {
            if (!isConnected) {
                toast.error("Please connect with the registered wallet address.");
                return
            }
          else if (address.toUpperCase() != loginDetails.walletAddress.toUpperCase()) {
                toast.error("Please connect with registered wallet address.")
                return
            }
            else {
                navigate(`${config.baseUrl}liquidityDetail/${id}`)
                window.scrollTo(0,0)
            }
        }
    }

    const openInfiSwapModule = async (e, id) => {
        e.preventDefault()
        if (Object.keys(loginDetails).length === 0) {
            navigate(`${config.baseUrl}login`);
            window.scrollTo(0,0)
        } else {
            if (!isConnected) {
                toast.error("Please connect with the registered wallet address.");
                return
            }
          else if (address.toUpperCase() != loginDetails.walletAddress.toUpperCase()) {
                toast.error("Please connect with registered wallet address.")
                return
            }
            else {
                navigate(`${config.baseUrl}infiDetail/${id}`)
                window.scrollTo(0,0)
            }            
        }
    }

    const filterUpcoming = upcommings.filter(item =>
        item.token_name.toLowerCase().includes(search.toLowerCase()) ||
        item.pair_name?.toLowerCase().includes(search.toLowerCase())
    )

    const filterListed = [
        ...(listed.presaleApplications || []),
        ...(listed.liquidityApplications || []),
        ...(listed.infiLiquidityApplications || [])].filter(item =>
        item.token_name.toLowerCase().includes(search.toLowerCase()) ||
        (item.pair_name ?? "").toLowerCase().includes(search.toLowerCase())
    )

    function formatLargeNumber(number) {
        if (number === null || number === undefined || number === "") {
            return ""; 
        }

        if (number >= 1e12) {
            return (number / 1e12) + "T"; // Trillions
        } else if (number >= 1e9) {
            return (number / 1e9) + "B"; // Billions
        } else if (number >= 1e6) {
            return (number / 1e6) + "M"; // Millions
        } else if (number >= 1e3) {
            return (number / 1e3) + "K"; // Thousands
        } else {
            return number.toString(); 
        }
    }
    



    return (
        <>
            <section className="projectList py-5 section_space">
                <Container>
                    <div>
                        <Row>
                            <Col md={9}>
                                <Slide>
                                    <h2>Project List</h2>
                                </Slide>
                            </Col>
                            <Col md={3}>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1"><IoSearch /></InputGroup.Text>
                                    <Form.Control
                                        placeholder="Search by Token Name, Pair Name"
                                        aria-describedby="basic-addon1"
                                        value={search}
                                        onChange={e => setSearch(e.target.value)}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                    </div>

                    <Tabs
                        defaultActiveKey="home"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                    >
                        <Tab eventKey="home" title={`Upcoming [${filterUpcoming && filterUpcoming.length}]`} >
                            {console.log('LLL',listed.presaleApplications)}
                            <div>
                                <Row>
                                    {filterUpcoming && filterUpcoming.length > 0 ? (
                                        filterUpcoming.map((item) => (
                                            item.type === 'Presale ICO' ?
                                                <Col md={6} lg={4} className="mt-3">
                                                    <Zoom>
                                                        <div className="card">
                                                            <div className="p-4">
                                                                <div className="text-center img_wrapper">
                                                                    <img src={`${config.imagePath}${item.brand_logo}`} alt="" className="img-fluid crd_img" />
                                                                </div>
                                                                <div className="d-flex justify-content-between align-items-center mb-3 mt-4">
                                                                    <b className="fs-3">{item.token_name}</b>

                                                                    <div>
                                                                        <img src={`${config.imagePath}${item.chainName}.png`} alt="" width={35} />
                                                                    </div>
                                                                </div>

                                                                <div>
                                                                    <div className="projectContent">
                                                                        <div className="d-flex justify-content-between">
                                                                            <span className="fs-5">Token Amount</span>
                                                                            <b className="fs-5">{item.token_amount}</b>
                                                                        </div>

                                                                        <div className="d-flex justify-content-between">
                                                                            <span className="fs-5">Price In USDT</span>
                                                                            <b className="fs-5">$ {item.usdt_price}</b>
                                                                        </div>

                                                                        <div className="d-flex justify-content-between">
                                                                            <span className="fs-5">Project Type</span>
                                                                            <b className="fs-5">{item.type}</b>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mt-4">
                                                                        {item.owner_id == loginDetails.id && <Button variant="dark" className="w-100" onClick={e => { sendToListingForm(e, item.id) }}>List your token</Button>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Zoom>
                                                </Col>
                                                :
                                                <Col md={6} lg={4} className="mt-3">
                                                    <Zoom>
                                                        <div className="card">
                                                            <div className="p-4">
                                                                <div className="text-center img_wrapper">
                                                                    <img src={`${config.imagePath}${item.brand_logo}`} alt="" className="img-fluid crd_img" />
                                                                </div>
                                                                <div className="d-flex justify-content-between align-items-center mb-3 mt-4">
                                                                    <b className="fs-3">{item.token_name}</b>

                                                                    <div>
                                                                        <img src={`${config.imagePath}${item.chainName}.png`} alt="" width={35} />
                                                                    </div>
                                                                </div>

                                                                <div>
                                                                    <div className="projectContent">
                                                                        <div className="d-flex justify-content-between">
                                                                            <span className="fs-5">Pair Name</span>
                                                                            <b className="fs-5">{item.pair_name}</b>
                                                                        </div>
                                                                        <div className="d-flex justify-content-between">
                                                                            <span className="fs-5">Project Type</span>
                                                                            <b className="fs-5">{item.type}</b>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mt-4">
                                                                        {item.pair_name === `${item.token_name}/INFI` ? (
                                                                            item.owner_id == loginDetails.id &&
                                                                            <Button
                                                                                variant="dark"
                                                                                className="w-100"
                                                                                onClick={e => { sendToInfiListingForm(e, item.id) }}>
                                                                                List your token
                                                                            </Button>
                                                                        ) : (
                                                                            item.owner_id == loginDetails.id &&
                                                                            <Button
                                                                                variant="dark"
                                                                                className="w-100"
                                                                                onClick={e => { sendToLiquidityListingForm(e, item.id) }}
                                                                            >List your token
                                                                            </Button>
                                                                        )
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Zoom>
                                                </Col>
                                        )
                                        )
                                    ) : (
                                        <div className="text-center">
                                            <p>No upcoming projects available</p>
                                        </div>
                                    )}
                                </Row>
                            </div>
                        </Tab>
                        <Tab eventKey="profile" title={`Listed [${filterListed && filterListed.length}]`}>
                            {console.log('XOO',filterListed)}
                            <div>
                                <Row>
                                    <Col md={12} lg={12} className="">
                                        <Zoom>
                                            <div className="listed">
                                                <Table responsive className="bg-transparent">
                                                    <thead>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Chain</th>
                                                            <th>Price</th>
                                                            <th>Project Type</th>
                                                            <th>Total Supply</th>
                                                            <th>Status</th>
                                                            <th>&nbsp;</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {filterListed && filterListed.length > 0 ? (
                                                            filterListed.map((item) => (
                                                                item.type === 'Presale ICO' ? (
                                                                    <tr key={item.id}>
                                                                        <td>
                                                                            <div className="d-flex lh-1">
                                                                                <div className="me-2">
                                                                                    <img src={`${config.imagePath}${item.brand_logo}`} width="28px" alt="logo"/>
                                                                                </div>
                                                                                <div className="lh-1">
                                                                                    <h5 className="mb-0">{item.token_name}</h5>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>{item.chainName}</td>
                                                                        <td>{isLoading ? <span>loading...</span> : `$${item.TOKEN_PRICE_PRESALE}`}</td>
                                                                        {/* <td style={{color: item.percentageChangePresale >= 0 ? 'green' : 'red'}}>
                                                                            {item.percentageChangePresale.toFixed(2)}%
                                                                        </td>
                                                                        <td>
                                                                            <Sparklines data={item.priceHistoryPresale} width={50} height={20}>
                                                                                <SparklinesLine color={item.percentageChangePresale >= 0 ? 'green' : 'red'}/>
                                                                            </Sparklines>
                                                                        </td> */}
                                                                        <td>{item.type}</td>
                                                                        <td>{isLoading ? <span>loading...</span> : formatLargeNumber(item.PRE_TOTAL)}</td>
                                                                        <td>
                                                                            {isLoading ? <span>loading...</span> : 
                                                                                item.duration ? (
                                                                                    <>
                                                                                         <span style={{
                                                                                            height: "8px",
                                                                                            width: "8px",
                                                                                            backgroundColor: "red",
                                                                                            borderRadius: "50%",
                                                                                            display: "inline-block",
                                                                                            marginRight: "5px"
                                                                                        }}></span>
                                                                                        Inactive
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        <span style={{
                                                                                            height: "8px",
                                                                                            width: "8px",
                                                                                            backgroundColor: "green",
                                                                                            borderRadius: "50%",
                                                                                            display: "inline-block",
                                                                                            marginRight: "5px"
                                                                                        }}></span>
                                                                                        Active
                                                                                    </>
                                                                                )}
                                                                            </td>
                                                                        <td>{
                                                                            <Button 
                                                                              variant="dark" 
                                                                              onClick={e => { 
                                                                                openBuyModule(e, item.id) }}
                                                                                >
                                                                                {item.duration ? 'Claim' : 'Buy'}
                                                                            </Button>}
                                                                        </td>
                                                                    </tr>
                                                                ) : (
                                                                    <tr key={item.id}
                                                                    style={{
                                                                        border: item.type === 'SPECIAL INFI SWAP' ? '2px solid #00bfff' :"",
                                                                        boxShadow: item.type === 'SPECIAL INFI SWAP' ? '0 0 8px #00bfff' : "",
                                                                        transition: 'box-shadow 0.3s ease',
                                                                      }}
                                                                    
                                                                    >
                                                                        <td>
                                                                            <div className="d-flex align-items-center lh-1">
                                                                            <div className="me-2">
                                                                                    <img src={`${config.imagePath}${item.brand_logo}`} width="28px" alt="logo"/>
                                                                                </div>
                                                                                <div className="lh-1">
                                                                                    <h5 className="mb-0">{item.token_name}</h5>
                                                                                </div>
                                                                            </div>
                                                                        </td>                                                                        
                                                                        <td>{item.chainName}</td>
                                                                        <td>{isLoading? <span>loading...</span> : item.pair_name === `${item.token_name}/INFI` ? `${item.TOKEN_PRICE_INFI_SWAP} ${item.token_name}/INFI` : `$${item.TOKEN_PRICE_SWAP}`}</td>
                                                                        <td>{item.type}</td>
                                                                        <td>{isLoading? <span>loading...</span> : item.pair_name === `${item.token_name}/INFI` ? formatLargeNumber(item.INFI_TOTAL) : formatLargeNumber(item.SWAP_TOTAL)}</td>
                                                                        <td>
                                                                            {isLoading ? <span>loading...</span> :
                                                                                item.duration ? (
                                                                                <>
                                                                                    <span style={{
                                                                                        height: "8px",
                                                                                        width: "8px",
                                                                                        backgroundColor: "red",
                                                                                        borderRadius: "50%",
                                                                                        display: "inline-block",
                                                                                        marginRight: "5px"
                                                                                    }}></span>
                                                                                    Inactive
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <span style={{
                                                                                        height: "8px",
                                                                                        width: "8px",
                                                                                        backgroundColor: "green",
                                                                                        borderRadius: "50%",
                                                                                        display: "inline-block",
                                                                                        marginRight: "5px"
                                                                                    }}></span>
                                                                                    Active
                                                                                </>
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            <Button
                                                                                variant="dark"
                                                                                onClick={e => {
                                                                                    item.pair_name === `${item.token_name}/INFI` ? openInfiSwapModule(e, item.id) : openSwapModule(e, item.id)
                                                                                }}
                                                                            >
                                                                                Trade
                                                                            </Button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="6" className="text-center">
                                                                    No listed projects available
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Zoom>
                                    </Col>

                                </Row>
                            </div>
                        </Tab>
                    </Tabs>
                </Container>
            </section >
        </>
    );
}   