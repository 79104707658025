import React, { useEffect, useState } from "react";
import { Row, Col, Container, Button, Card, Form } from "react-bootstrap";
import config from "../../config";
import toast, { Toaster } from "react-hot-toast";
import { verifyLink } from "../../Action/action"
import { useNavigate, useLocation, Link } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import { FaCheck } from "react-icons/fa6";
import './css/verifyemail.css'

const Mailverification = () => {

  useEffect(() => {
    // Add a class to the body
    document.body.classList.add("lighttheme");

    // Cleanup: Remove the class when the component is unmounted
    return () => {
      document.body.classList.remove("lighttheme");
    };
  }, []);

  const [loading, setLoading] = useState(true);
  const [verified, setVerified] = useState(false);
  const [called, setCalled] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();


  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  console.log(queryParams, token, 'zzzzzz')

  useEffect(() => {
    if (!token) {
      toast.error("Token is missing! Redirecting to login...");
      setTimeout(() => {
        navigate(`${config.baseUrl}login`);
        window.scrollTo(0,0)
      }, 2000);
      return;
    }
  }, [token, navigate]);

  const verifyEmails = async () => {
    setCalled(true)
    try {
      const response = await verifyLink({ token });
      console.log('xyz', response)
      if (response.success) {
        setVerified(true);
        setLoading(false);
      } else {
        setVerified(false);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.data) {
        toast.error(error.response.data.message || "Verification failed");
      } else {
        toast.error("Something went wrong! Please try again.");
      }
    }
  };
  useEffect(() => {
    if (!called) {
      verifyEmails();
    }
  }, [])

  return (
    <>
      <Toaster />
      <section className="loginform pt-0">
        <Container>
          <Row className="justify-content-center vh-100 align-items-center">
            <Col lg={5}>
              <Card className="shadow border-0 ">
                <Card.Body className="p-4">
                  <div>
                    <Form>
                      {loading ?
                        <Row>
                          <Col lg={12} className="">
                            <div className="text-center">
                              <div className="banner-heading text-center py-2 mb-0">

                                <p className=" mb-0  mb-0 fs-4">
                                  Please wait we are verifying your request...{" "}
                                  {/* <span className="outline-txt">SUCCESSFULLY </span>
                              COMPLETED ! */}
                                </p>
                              </div>
                            </div>
                          </Col>

                        </Row>
                        :
                        <Row>
                          <Col lg={12} className="mb-3">
                            <div className="text-center mb-3">
                         

                              {verified ?
                                <div className="banner-heading text-center py-2 mb-1">
                                  <div className="checkIcon mb-4 btn btn-success">
                                    <FaCheck size={`40`} fill="#fff"/>
                                  </div>

                                  <h2 className="fw-bold mb-0 text-uppercase mb-3">
                                    VERIFICATION{" "}
                                    <span className="outline-txt">SUCCESSFULLY </span>
                                    COMPLETED !
                                  </h2>

                                  <p className="mb-0">You can login now.</p>
                                </div>
                                :
                                <div className="banner-heading text-center py-2 mb-1">

                                  <div className="closeIcon mb-4 btn btn-danger">
                                    <IoClose size={`40`} fill="#fff"/>
                                  </div>

                                  <h2 className="fw-bold mb-0 text-uppercase mb-3">
                                    VERIFICATION{" "}
                                    <span className="outline-txt">UNSUCCESSFUL </span>
                                    SORRY !
                                  </h2>
                                  <p className="mb-0">Sorry something went wrong. Try to signup again or contect us.</p>
                                </div>}
                            </div>
                          </Col>
                          <Col lg={12}>
                            <div className="d-flex gap-2">
                              <Link to={`${config.baseUrl}login`} className="w-100">
                                <Button variant="primary" className="w-100 rounde-2">
                                  Continue
                                </Button>
                              </Link>
                            </div>
                          </Col>
                        </Row>}
                    </Form>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

        <div></div>
      </section>
    </>
  );
};

export default Mailverification;