

const config = {
baseUrl: '/',
serverPath: "https://launchpad.infimultichain.com/users",
imagePath: "https://launchpad.infimultichain.com/media",    
// serverPath: 'http://localhost:8000/users',
// imagePath: 'http://localhost:8000/users/uploads/',
//  serverPath: 'https://espsofttech.org:4321/users',
//  imagePath: 'https://espsofttech.org:4321/users/uploads/',
priceAPI: 'https://api.coingecko.com/api/v3/coins/markets'
};

export default config;


    