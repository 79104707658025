import React, { useState, useEffect } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { Button, Col, Container, Form, Row,DropdownButton,Dropdown,InputGroup } from "react-bootstrap";
import { getActiveChains, submitApplicationFrom } from "../../Action/action";
import { checkContractOnChain } from "../../Web3/web3FunctionCall";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import ReactApexChart from 'react-apexcharts';
import { Link, useNavigate } from "react-router-dom";
import config from "../../config";
import { useDisconnect, useAccount } from "wagmi";
import Web3WalletProvider from '../../ConnectWallet/walletProvider'
import { TokenSupply } from "../../Web3/Connector";


export default function LaunchProcessForm() {
  const [form, setForm] = useState({
    tokenName: "",
    tokenAddress: "",
    chainNetwork: "",
    description: "",
    duration: "",
    logo: null,
    kyc: "",
    audit: "",
    website: "",
    telegram: "",
    youtube: "",
    linkedin: "",
//   liquidityValue:"",
    privateSaleValue: "",
    icoValue: "",
    team:"",
    partnerships:"",
    others:"",
    hardCap: "",
    softCap: "",
    tokenAmount: "",
    usdtPrice: "",
    //nativePrice: "",
    whitepaper: "",
    socialAccounts: [{ name: "", link: "" }],
    agree: false,
  });

  const [errors, setErrors] = useState({});
  const [chainOptions, setChainOptions] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  const [getRpc, setRpc] = useState();
  const dispatch = useDispatch();
  const [update, setUpdate] = useState(false);
  const loginDetails = useSelector((state) => state.auth.LoginDetails);
  const percentage = [60,65,75,80,85,90,95,100]
  const [percent, setPercent] = useState(60)
  const [supply, setSupply] = useState(0)
  const [chainId, setChainId] = useState('')
  let _useWeb3WalletProvider = Web3WalletProvider()
  const {address, isConnected} = useAccount()


  const [chart, setChart] = useState({
    series: [0,0,0,0,0],
    options: {
      chart: {
        type: 'donut',
      },
      labels: ['Private Sale', 'ICO', 'Team', 'Partnerships', 'Others'],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      }]
    }
  })

  const [fields, setFields] = useState([{ name: "" }]);
  // /------
  //  charts
  // -----------
  // Function to handle adding new fields
  const handleAddField = () => {
    setFields([...fields, { name: "" }]);
  };

  // Function to handle removing fields
  const handleRemoveField = (index) => {
    const newFields = fields.filter((_, i) => i !== index);
    setFields(newFields);
  };

  // Function to handle input change
  const handleChange = (index, event) => {
    const newFields = [...fields];
    newFields[index].name = event.target.value;
    setFields(newFields);
  };

  // Function to handle form submission
  const handleSubmit2 = (event) => {
    event.preventDefault();
    console.log("Submitted Fields:", fields);
  };

  // ----------
  //console.log("LOGIN",loginDetails)
  const navigate = useNavigate();

  useEffect(() => {
    getChains();
  }, []);

  useEffect(() => {
    if(form.hardCap && percent) {
      let softcap = (form.hardCap * percent) / 100;
      setForm((prevForm) => ({
        ...prevForm,
        softCap: softcap
      }))
    }
  },[form.hardCap, percent])

  const handlePercentChange = (e) => {
    let value = parseInt(e.target.value)
    setPercent(value)
  }


  const getChains = async () => {
    try {
      const response = await getActiveChains(loginDetails);
      if (response.success) {
        setChainOptions(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    if (name === "chainNetwork") {
      for (let i = 0; i < chainOptions.length; i++) {
        if (chainOptions[i].id == value) {
          setRpc(chainOptions[i].chainRPCUrl);
          setChainId(chainOptions[i].chainNetworkId)
        }
      }
      setErrors((prevErrors) => ({
        ...prevErrors,
        chainNetwork: ""
      }))
    }
    if (name === 'duration' && value === 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        duration: 'Sale duration cannot be 0',
      }));
    }
    if (name === "tokenAddress" ) {
      if(!form.chainNetwork) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        chainNetwork: "Please select a chain network before entering the token address."
      }))
      return
    }
      const totalTokenSupply = async () => {
        let request = {
          walletProvider:  _useWeb3WalletProvider,
          tokenAddress:value,
          networkId:chainId
        }
        let res = await TokenSupply(request)
        //console.log('SSSSS',s.supply)
        let supplyValue = (Number(res.supply) / 10**18)
        setSupply(supplyValue)

      } 
      await totalTokenSupply()
    }

    setForm({
      ...form,
      [name]: value,
    });
    if (['privateSaleValue', 'icoValue', 'team', 'partnerships', 'others'].includes(name)) {
      updateChart({...form, [name]: value})
    }
  };

useEffect (() => {
  setForm((prevForm) => ({
    ...prevForm,
    liquidityValue: supply
  }));
},[supply])

  const updateChart = (updateForm) => {
    const {privateSaleValue, icoValue, team, partnerships,others} = updateForm

    const totalPercent = (parseFloat(privateSaleValue) || 0) + 
    (parseFloat(icoValue) || 0) +
    (parseFloat(team) || 0) +
    (parseFloat(partnerships) || 0) +
    (parseFloat(others) || 0)

    if (totalPercent > 100) {
      toast.error('Total percentage cannot exceed 100%')
      return;
    }

    setChart((prevData) => ({
      ...prevData,
      series:[
        ( (parseFloat(privateSaleValue) || 0) * supply / 100),
        ((parseFloat(icoValue) || 0) * supply / 100),
        ((parseFloat(team) || 0) * supply / 100),
        ((parseFloat(partnerships) || 0) * supply / 100),
        ((parseFloat(others) || 0) * supply / 100)
      ]
    }))
  }

  const chartStyle = {
    maxWidth: '400px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#f9f9f9',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0]
    const validImageTypes = [
      'image/jpeg',  
      'image/png',   
      'image/gif',   
      'image/bmp',   
      'image/webp',  
      'image/svg+xml' 
    ];

    if (file && validImageTypes.includes(file.type)) {

      setForm({ 
        ...form,
        logo: file,

    });
    setImagePreview(URL.createObjectURL(file));
  } else {
    alert('Please select a valid image file (jpeg, png, gif, bmp, webp, svg)');
    }
  }

  const handleSocialAccountChange = (index, e) => {
    const { name, value } = e.target;
    const socialAccounts = [...form.socialAccounts];
    socialAccounts[index][name] = value;
    setForm({
      ...form,
      socialAccounts,
    });
  };

  const addSocialAccount = () => {
    setForm({
      ...form,
      socialAccounts: [...form.socialAccounts, { name: "", link: "" }],
    });
  };



  const handleCheckboxChange = (e) => {
    setForm({
      ...form,
      agree: e.target.checked,
    });
  };



//   const validateForm = async () => {
//     const newErrors = {};
//     const urlPattern =
//       /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
//       // const isInvalidNumber = (value) => isNaN(value) || value === '' 
//       const isInvalidNumber = (value) => {
//   const numberPattern = /^\d+(\.\d+)?$/; // Allows only positive numbers with optional decimals
//   return !numberPattern.test(value) || isNaN(value) || value <= 0;
// };

//     if (!form.tokenName) newErrors.tokenName = "Token Name is required";
//     if (!form.tokenAddress)
//       newErrors.tokenAddress = "Token Address is required";
//     if (!form.description) newErrors.description = "Description is required";
//     if (!form.duration) {
//       newErrors.duration = "Duration is required";
//     } else if (form.duration <=0 ) {
//       newErrors.duration = "Duration must be greater than 0";      
//     } else if (isInvalidNumber(form.duration)) {
//       newErrors.duration = "Duration must be a valid number"
//     }
//     if (!form.logo) newErrors.logo = "Logo is required";
//     // if (!form.kyc) newErrors.kyc = "KYC is required";
//     // if (!form.audit) newErrors.audit = "Audit is required";
//     if (!form.liquidityValue) {
//       newErrors.liquidityValue = "Liquidity value is required";
//     } else if (isInvalidNumber(form.liquidityValue)) {
//       newErrors.liquidityValue = "Liquidity must be a valid number"
//     }
//     if (!form.privateSaleValue) {
//       newErrors.privateSaleValue = "Private Sale value is required";
//     } else if (form.privateSaleValue < 0) {
//       newErrors.privateSaleValue = "Private Sale value cannot be negative";      
//     } else if (isInvalidNumber(form.privateSaleValue)) {
//       newErrors.privateSaleValue = "Private Sale Value must be a valid number"
//     }
//     if (!form.icoValue) {
//       newErrors.icoValue = "ICO value is required";
//     }  else if (form.icoValue < 0) {
//       newErrors.icoValue = "ICO value cannot be negative";      
//     } else if (isInvalidNumber(form.icoValue)) {
//       newErrors.icoValue = "ICO Value must be a valid number"
//     }
//     if (!form.team) {
//       newErrors.team = "Team value is required";
//     } else if (form.team < 0) {
//       newErrors.team = "Team value cannot be negative";      
//     } else if (isInvalidNumber(form.team)) {
//       newErrors.team = "Team must be a valid number"
//     }
//     if (!form.partnerships) {
//       newErrors.partnerships = "Partnership value is required";
//     } else if (form.partnerships < 0) {
//       newErrors.partnerships = "Partnership value cannot be negative";      
//     } else if (isInvalidNumber(form.partnerships)) {
//       newErrors.partnerships = "Partnerships must be a valid number"
//     }
//     if (!form.others) {
//       newErrors.others = "Value is required";
//     } else if (form.others < 0) {
//       newErrors.others = "Value cannot be negative";      
//     } else if (isInvalidNumber(form.others)) {
//       newErrors.others = "Value must be a valid number"
//     }
//     if (!form.hardCap) {
//       newErrors.hardCap = "Hard cap value is required";
//     }  else if (form.hardCap < 0) {
//       newErrors.hardCap = "Hard cap value cannot be negative";      
//     } else if (isInvalidNumber(form.hardCap)) {
//       newErrors.hardCap = "Hard Cap must be a valid number"
//     }
//     if (!form.whitepaper) {
//       newErrors.whitepaper = "Whitepaper is required";
//     }
//     if (!form.tokenAmount) {
//       newErrors.tokenAmount = "Token amount value is required";
//     }  else if (form.tokenAmount < 0) {
//       newErrors.tokenAmount = "Token Amount cannot be negative";      
//     } else if (isInvalidNumber(form.tokenAmount)) {
//       newErrors.tokenAmount = "Token Amount must be a valid number"
//     }
//     if (!form.usdtPrice) {
//       newErrors.usdtPrice = "USDT price is required";
//     } else if (form.usdtPrice < 0) {
//       newErrors.usdtPrice = "Price cannot be negative";      
//     } else if (isInvalidNumber(form.usdtPrice)) {
//       newErrors.usdtPrice = "Usdt Price must be a valid number"
//     }
//     if (!form.agree) newErrors.agree = "Please fill in all the required fields."
//     let result = await checkContractOnChain(getRpc, form.tokenAddress);
//     if (!result.status) newErrors.tokenAddress = `${result.msg}`;
//     const socialLinks = ["website", "telegram", "youtube", "linkedin"];
//     socialLinks.forEach((field) => {
//       if (form[field] && !urlPattern.test(form[field])) {
//         newErrors[field] = `Invalid URL format for ${field}`;
//       }
//     });
//     console.log(newErrors)
//     setErrors(newErrors);
//     return Object.keys(newErrors).length === 0;
//   }; 

const validateForm = async () => {
  const newErrors = {};
  const urlPattern =
    /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/i;

  const isInvalidNumber = (value) => isNaN(value) || value === '' || value <= 0;
  const requiredFields = [
    "tokenName",
    "tokenAddress",
    "description",
    "logo",
    "liquidityValue",
    "privateSaleValue",
    "icoValue",
    "team",
    "partnerships",
    "others",
    "hardCap",
    "whitepaper",
    "tokenAmount",
    "usdtPrice",
  ];

  requiredFields.forEach((field) => {
      if (["liquidityValue", "privateSaleValue", "icoValue", "team", "partnerships", "others", "hardCap", "tokenAmount", "usdtPrice"].includes(
        field
      ) &&
      isInvalidNumber(form[field])
    ) {
      newErrors[field] = `${field} must be a valid positive number`;
    }
  });
  if (!form.duration) {
    newErrors.duration = "Duration is required";
  } else if (isInvalidNumber(form.duration)) {
    newErrors.duration = "Duration must be a valid positive number";
  }
  if (!form.agree) {
    newErrors.agree = "You must agree to the terms";
  }
  const socialLinks = ["website", "telegram", "youtube", "linkedin"];
  socialLinks.forEach((field) => {
    if (form[field] && !urlPattern.test(form[field])) {
      newErrors[field] = `Invalid URL format for ${field}`;
    }
  });
  let result = await checkContractOnChain(getRpc, form.tokenAddress);
  if (!result.status) {
    newErrors.tokenAddress = `${result.msg}`;
  }

  console.log(newErrors);
  setErrors(newErrors);
  return Object.keys(newErrors).length === 0;
};


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (Object.keys(loginDetails).length === 0) {
      navigate(`${config.baseUrl}login`);
      window.scrollTo(0,0)
    } else {
    if(!isConnected) {
      toast.error("Please connect with the registered wallet address.");
      return
    }
    if(address.toUpperCase() != loginDetails.walletAddress.toUpperCase()) {
      toast.error("Please connect with registered wallet address")
      return
    }

    if (await validateForm()) {
      console.log("Form submitted", form);


      form.ownerId = loginDetails.id;
      form.socialAccount = JSON.stringify(form.socialAccounts);
      let result = await submitApplicationFrom(form, loginDetails)

      if (result.success) {
        toast.success(result.msg);
        setTimeout(() => {
          navigate(`${config.baseUrl}home`);
          window.scrollTo(0,0)
        }, 2000);
      } else {
        toast.error(result.msg)
      }

    } else {
      toast.error("Please check the validations required.");
    }
  }
  };

  return (
    <>
      <Toaster />
      <Header />
      <section className="process_form">
        <Container>
          <Form >
            <div className="from_box my-5">
              <h2>The Launch process:</h2>
              <p>
                Must be confirmed with the Owner wallet (the tokens will be
                transferred to the INFI CDEX Launchpool)
              </p>

              <p
                style={{
                  textAlign:"right",
                  fontSize: "12px",
                  fontStyle: "italic",
                  marginTop:"-10px"
                }}
              >Fields marked with * are compulsory</p>

              <Row className="">
                <Col lg={6} className="mb-4">
                  <Form.Label>Token Name *</Form.Label>
                  <Form.Control
                    type="text"
                    name="tokenName"
                    value={form.tokenName}
                    onChange={handleInputChange}
                    isInvalid={!!errors.tokenName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.tokenName}
                  </Form.Control.Feedback>
                </Col>

                <Col lg={6} className="mb-4">
                  <Form.Label>Chain Network *</Form.Label>
                  <Form.Control
                    as="select"
                    name="chainNetwork"
                    value={form.chainNetwork}
                    onChange={handleInputChange}
                    isInvalid={!!errors.chainNetwork}
                  >
                    <option value="">Select Network</option>
                    {chainOptions.map((option, index) => (
                      <option key={index} value={option.id}>
                        {option.chainName}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.chainNetwork}
                  </Form.Control.Feedback>
                </Col>

                <Col lg={6} className="mb-4">
                  <Form.Label>Token Address *</Form.Label>
                  <Form.Control
                    type="text"
                    name="tokenAddress"
                    value={form.tokenAddress}
                    onChange={handleInputChange}
                    isInvalid={!!errors.tokenAddress}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.tokenAddress}
                  </Form.Control.Feedback>
                </Col>

                <Col lg={12} className="mb-4">
                  <Form.Label>Description *</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="description"
                    placeholder="max 1000 characters"
                    value={form.description}
                    onChange={handleInputChange}
                    isInvalid={!!errors.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.description}
                  </Form.Control.Feedback>
                </Col>

                <Col lg={12} className="mb-4">
                  <Form.Label>ADD a Brand Logo/Upload a Logo *</Form.Label>
                  <div className="position-relative">
                    <Form.Control
                      type="file"
                      className="custom_dragdrop_form"
                      onChange={handleFileChange}
                      isInvalid={!!errors.logo}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.logo}
                    </Form.Control.Feedback>
                    <div className="custom_dragdrop my-3">
                      <span>
                        Click to upload files, or drag & drop files here
                      </span>
                    </div>
                    {imagePreview && (
                      <div className="image-preview mt-3">
                        <img
                          src={imagePreview}
                          alt="Preview"
                          style={{ maxHeight: "200px", maxWidth: "100%" }}
                        />
                      </div>
                    )}
                  </div>
                </Col>

                <Col lg={6} className="mb-4">
                  <Form.Label>ADD KYC</Form.Label>
                  <Form.Control
                    type="text"
                    name="kyc"
                    value={form.kyc}
                    onChange={handleInputChange}
                    isInvalid={!!errors.kyc}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.kyc}
                  </Form.Control.Feedback>
                </Col>

                <Col lg={6} className="mb-4">
                  <Form.Label>ADD Audit</Form.Label>
                  <Form.Control
                    type="text"
                    name="audit"
                    value={form.audit}
                    onChange={handleInputChange}
                    isInvalid={!!errors.audit}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.audit}
                  </Form.Control.Feedback>
                </Col>

                <Col lg={12} className="">
                  <Form.Label>Social Platform</Form.Label>
                  {fields.map((field, index) => (
                    <div key={index}>
                      <Form.Control
                        type="text"
                        name="website"
                        className="mb-2"
                        placeholder="Website (if any)"
                        value={field.name}
                        onChange={(event) => handleChange(index, event)}
                      // value={form.website}
                      // onChange={handleInputChange}
                      />
                    </div>
                  ))}
                  <div className="text-end">
                    <Button variant="add" onClick={handleAddField} className="p-0">
                      + Add more
                    </Button>
                  </div>

                </Col>
              </Row>

              <Row className="align-items-end">
                <Col lg={6} className="mb-3">
                  <Form.Label>Presale Hard Cap *</Form.Label>
                  <Form.Control
                    type="number"
                    name="hardCap"
                    placeholder="Maximum USDT you expect to earn from Presale"
                    value={form.hardCap}
                    onChange={handleInputChange}
                    isInvalid={!!errors.hardCap}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.hardCap}
                  </Form.Control.Feedback>
                </Col>
                <Col lg={6} className="mb-3">
                </Col>
                <Col lg={6} className="mb-3">

                  
                  <Form.Label>Presale Soft Cap *</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control aria-label="Text input with dropdown button"
                    type="number"
                    name="softCap"
                    placeholder="Minimum USDT earning required to list on CDEX"
                    value={form.softCap}
                    onChange={handleInputChange}
                    isInvalid={!!errors.softCap}
                    disabled={true}
                    
                    />
                    <Form.Select
                      value={percent}
                      onChange={handlePercentChange}
                      variant="outline-secondary"
                      title="60%"
                      className="
                      percentageDropdown"
                      id="input-group-dropdown-2"
                      style={{maxWidth:"90px"}}
                      align="end"
                    >
                    {percentage.map((option, index) => (
                      <option key={index} value={option}>
                        {option} %
                      </option>
                    ))}
                    </Form.Select>
                  </InputGroup>
                </Col>
              </Row>

              <Row className="mb-4">
                <h4 className="mb-4">Tokenomics *</h4>

                <Col lg={12}>
                  <Row>
                    <Col lg={6} className="mb-4">
                      <Row>

                        <Col lg={6} className="mb-4">
                          <Form.Label>Total Supply</Form.Label>

                          <Form.Control
                            type="number"
                            name="liquidityValue"
                            placeholder="Value"
                            value={supply}
                            onChange={handleInputChange}
                            isInvalid={!!errors.liquidityValue}
                            disabled={!update?true:false}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.liquidityValue}
                          </Form.Control.Feedback>
                        </Col>

                        <Col lg={6} className="mb-4">
                          <Form.Label>Private Sale *</Form.Label>

                          <Form.Control
                            type="number"
                            name="privateSaleValue"
                            placeholder="Value (In % percentage)"
                            value={form.privateSaleValue}
                            onChange={handleInputChange}
                            isInvalid={!!errors.privateSaleValue}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.privateSaleValue}
                          </Form.Control.Feedback>
                        </Col>

                        <Col lg={6} className="mb-4">
                          <Form.Label>ICO Sale *</Form.Label>
                          <Form.Control
                            type="number"
                            name="icoValue"
                            placeholder="Value (In % percentage)"
                            value={form.icoValue}
                            onChange={handleInputChange}
                            isInvalid={!!errors.icoValue}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.icoValue}
                          </Form.Control.Feedback>
                        </Col>
                        <Col lg={6} className="mb-4">
                          <Form.Label>Team *</Form.Label>
                          <Form.Control
                            type="number"
                            name="team"
                            placeholder="Value (In % percentage)"
                            value={form.team}
                            onChange={handleInputChange}
                            isInvalid={!!errors.team}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.team}
                          </Form.Control.Feedback>
                        </Col>
                        <Col lg={6} className="mb-4">
                          <Form.Label>Partnerships *</Form.Label>
                          <Form.Control
                            type="number"
                            name="partnerships"
                            placeholder="Value (In % percentage)"
                            value={form.partnerships}
                            onChange={handleInputChange}
                            isInvalid={!!errors.partnerships}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.partnerships}
                          </Form.Control.Feedback>
                        </Col>
                        <Col lg={6} className="mb-4">
                          <Form.Label>Others *</Form.Label>
                          <Form.Control
                            type="number"
                            name="others"
                            placeholder="Value (In % percentage)"
                            value={form.others}
                            onChange={handleInputChange}
                            isInvalid={!!errors.others}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.others}
                          </Form.Control.Feedback>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={6} className="mb-4">
                      <div id="chart" style={chartStyle}>
                        <ReactApexChart options={chart.options} series={chart.series} type="donut" />
                      </div>
                    </Col>
                  </Row>

                </Col>

                <Col lg={6} className="mb-4">
                  <Form.Label>Token Amount For Presale *</Form.Label>

                  <Form.Control
                    type="number"
                    name="tokenAmount"
                    placeholder="Value"
                    value={form.tokenAmount}
                    onChange={handleInputChange}
                    isInvalid={!!errors.tokenAmount}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.tokenAmount}
                  </Form.Control.Feedback>
                </Col>

                <Col lg={6} className="mb-4">
                  <Form.Label>Token Price(IN USDT) *</Form.Label>
                  <Form.Control
                    type="number"
                    name="usdtPrice"
                    placeholder="Value"
                    value={form.usdtPrice}
                    onChange={handleInputChange}
                    isInvalid={!!errors.usdtPrice}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.usdtPrice}
                  </Form.Control.Feedback>
                </Col>

                {/* <Col lg={6} className="mb-4">
                  <Form.Label>Native price</Form.Label>

                  <Form.Control
                    type="text"
                    name="nativePrice"
                    placeholder="Value"
                    value={form.nativePrice}
                    onChange={handleInputChange}
                    isInvalid={!!errors.nativePrice}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.nativePrice}
                  </Form.Control.Feedback>
                </Col> */}

                <Col lg={6} className="mb-4">
                  <Form.Label>Sale Duration *</Form.Label>

                  <Form.Control
                    type="number"
                    name="duration"
                    placeholder="In days"
                    value={form.duration}
                    onChange={handleInputChange}
                    isInvalid={!!errors.duration}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.duration}
                  </Form.Control.Feedback>
                </Col>
              </Row>

              <Row className="mb-4">
                <Col lg={12}>
                  <Form.Label>Whitepaper *</Form.Label>
                  <Form.Control
                    type="text"
                    name="whitepaper"
                    placeholder="Add a whitepaper link here."
                    value={form.whitepaper}
                    onChange={handleInputChange}
                  />
                </Col>

                <Form.Label className="mt-4">
                  Project Social Accounts (Up to 10 accounts)
                </Form.Label>
                {form.socialAccounts.map((account, index) => (
                  <React.Fragment key={index}>
                    <Col lg={6} className="mb-4">
                      <Form.Control
                        type="text"
                        name="name"
                        placeholder="Social Account name"
                        value={account.name}
                        onChange={(e) => handleSocialAccountChange(index, e)}
                      />
                    </Col>
                    <Col lg={6} className="mb-4">
                      <Form.Control
                        type="text"
                        name="link"
                        placeholder="Add a link here."
                        value={account.link}
                        onChange={(e) => handleSocialAccountChange(index, e)}
                      />
                    </Col>
                  </React.Fragment>
                ))}
                <div className="text-end">
                  <Button variant="add" onClick={addSocialAccount}>
                    + Add more
                  </Button>
                </div>
              </Row>

              <div className="mt-5">
                <h6>
                  IMPORTANT: Special Liquidity Management SpecialLM - must agree *
                </h6>
                <p>
                  (Here is the description for the project owners, what they
                  have to agree upon prior to listing their project. This will
                  be a contract between INFI CDEX and the starting project that
                  needs to be <b>AGREE</b> and <b>CONFIRM</b> by the project
                  owner)
                </p>
              </div>

              <div className="d-flex align-items-baseline">
                <Form.Check
                  className="me-2"
                  checked={form.agree}
                  onChange={handleCheckboxChange}
                  isInvalid={!!errors.agree}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.agree}
                </Form.Control.Feedback>
                <span>
                  Project owners must lock liquidity for a minimum of six
                  months, during which the liquidity remains inaccessible.
                  Safeguards are in place for companies to investigate and halt
                  trading if a scam or suspicious transaction is spotted. In
                  case of a confirmed scam, investors take precedence, impacting
                  the locked liquidity. Post the lock period, project owners
                  need approval from INFI ADMIN to access the liquidity, and
                  must justify their request
                </span>
              </div>

              <hr />

              <div className="mb-4 text-center">
                <Button variant="dark" className="px-4 py-2" onClick={e => { handleSubmit(e) }} >
                  Submit
                </Button>
                <p className="mt-2">
                  Never submit password or credit card details through workform
                </p>
              </div>
            </div>
          </Form>
        </Container>
      </section>
      <Footer />
    </>
  );
}