import React, { useEffect, useState } from "react";
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import * as ACTIONTYPES from "../../redux/actionTypes"
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import config from "../../config/index"
import toast, { Toaster } from "react-hot-toast";

//
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount } from "wagmi";

import { Button } from 'react-bootstrap';


export default function Header() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation()
    const loginDetails = useSelector((state) => state.auth.LoginDetails);
    const isLoggedIn = loginDetails && Object.keys(loginDetails).length > 0 && loginDetails.authToken;

    //
    const { isConnected, address, chainId, isDisconnected, chain } = useAccount()
    const { isOpen, open, close } = useWeb3Modal()

    const show = (e) => {
        e.preventDefault()
        try {
            open()
        } catch (error) {
            console.log('Err', error)
        }
    }

    const logout = (e) => {
        e.preventDefault()
        dispatch({
            type: ACTIONTYPES.USERLOGOUT,
        });
        navigate(`${config.baseUrl}login`);
        window.scrollTo(0,0)
    }

    const goTo = (e) => {
        e.preventDefault()
        navigate(`${config.baseUrl}home`)
        window.scrollTo(0,0)
    }

    const signup = (e) => {
        e.preventDefault()
        navigate(`${config.baseUrl}`)
        window.scrollTo(0,0)
    }

    const login = (e) => {
        e.preventDefault()
        navigate(`${config.baseUrl}login`)
        window.scrollTo(0,0)
    }

    return (
        <>
            <Toaster />
            <section className='header'>
                <>
                    <Navbar expand="xl" className="bg-body-tertiary py-0">
                        <Container>
                            <Navbar.Brand href="#">
                                <div className="logo d-block d-md-flex">
                                    <div>
                                        {location.pathname === `${config.baseUrl}home` ? (
                                            <img
                                                src={`${config.baseUrl}images/logos/logoLaunchPadNew.png`}
                                                alt="home"
                                                onClick={(e) => goTo(e)}
                                                width={180}
                                            />
                                        ) : (
                                            <img
                                                src={`${config.baseUrl}images/logos/tradinglogo.png`}
                                                alt="trade"
                                                onClick={(e) => goTo(e)}
                                                width={180}
                                            />
                                        )}
                                    </div>
                                </div>
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="navbarScroll" />
                            <Navbar.Collapse id="navbarScroll">
                                <Nav
                                    className="me-auto my-2 my-lg-0 justify-content-center w-100"
                                    navbarScroll
                                >
                                    <Nav.Link className="me-3" href={`${config.baseUrl}howitwork`} >How It Works</Nav.Link>
                                    <Nav.Link className="me-3" href={`${config.baseUrl}about`}>About Us</Nav.Link>
                                    <Nav.Link className="me-3" href={`${config.baseUrl}faq`} >FAQ</Nav.Link>
                                    <Nav.Link className="me-3" href={`${config.baseUrl}contact`}>Contact Us</Nav.Link>
                                    <Nav.Link className="me-3" href="https://infimultichain.com/ico" target="_blank" rel="noopener noreferrer">INFI ICO</Nav.Link>
                                </Nav>
                                <Form className="d-sm-flex align-items-center justify-content-end">
                                    <h4 className="text-gradient">
                                        <b>
                                            {console.log('qqqq', isConnected, address, chainId)}
                                        </b>
                                    </h4>
                                    <div className=" mt-md-4 mb-4">
                                        <div className="connect-btn d-flex ">
                                            {chain && (
                                                <>
                                                    <span
                                                        style={{
                                                            display: 'inline-block',
                                                            width: '8px',
                                                            height: '8px',
                                                            backgroundColor: 'lightgreen',
                                                            borderRadius: '50%',
                                                            marginRight: '6px',
                                                            marginTop: '15px'
                                                        }}
                                                    ></span>
                                                    <span
                                                        style={{
                                                            className: "me-3",
                                                            fontWeight: 'bold',
                                                            whiteSpace: 'nowrap',
                                                            marginRight: '20px',
                                                            marginTop: '5px'
                                                        }}
                                                    >
                                                        {isConnected ? chain.name.toString() : ""}
                                                    </span>
                                                </>
                                            )}
                                            <Button
                                                variant="primary"
                                                className="text-nowrap"
                                                onClick={(e) => show(e)}
                                            >
                                                {isConnected ? address.toString().substring(0, 4) + "...." + address.toString().substring(address.length - 4, address.length) : "Connect Wallet"}
                                            </Button>


                                        </div>
                                    </div>

                                    {isLoggedIn ? (
                                        <>
                                            <Button
                                                variant="dark"
                                                onClick={e => { logout(e) }}
                                                className="ms-sm-3 h-auto rounded-5 px-4 text-nowrap">
                                                Logout
                                            </Button>
                                        </>
                                    ) : (
                                        <>
                                            <Button
                                                variant="dark"
                                                onClick={e => { login(e) }}
                                                className="ms-sm-3 h-auto rounded-5 px-4 text-nowrap">
                                                Login
                                            </Button>

                                            <Button
                                                variant="dark"
                                                onClick={e => { signup(e) }}
                                                className="ms-3 h-auto rounded-5 px-4 text-nowrap">
                                                Sign Up
                                            </Button>
                                        </>
                                    )}
                                </Form>
                            </Navbar.Collapse>

                        </Container>
                    </Navbar>
                </>
            </section>
        </>
    )
}