import React ,{useState, useEffect} from "react";
import { Container, Row } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from "swiper/modules";

import { Zoom } from "react-awesome-reveal";
import config from "../../config";
import Marquee from "react-fast-marquee";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";


export default function Banner() {
    const navigate = useNavigate();
    const [tokenPrices, setTokenPrices] = useState([])
    let loginDetails = useSelector((state) => state.auth.LoginDetails);
    //console.log('LLLLLLLLLLLLLL',loginDetails)

    const applicationForm = () => {
        if (Object.keys(loginDetails).length === 0) {
            navigate(`${config.baseUrl}login`);
            window.scrollTo(0,0)
        } else {
        navigate(`${config.baseUrl}launchprocessform`);
        window.scrollTo(0,0)
    }
}

    const liquidityApplicationForm = () => {
        if (Object.keys(loginDetails).length === 0) {
            navigate(`${config.baseUrl}login`);
            window.scrollTo(0,0)
        } else {
        navigate(`${config.baseUrl}liquidityListingForm`);
        window.scrollTo(0,0)
        } 
    }

    const infiApplicationForm = () => {
        if (Object.keys(loginDetails).length === 0) {
            navigate(`${config.baseUrl}login`);
            window.scrollTo(0,0)
        } else {
        navigate(`${config.baseUrl}infiListingForm`);
        window.scrollTo(0,0)
        } 
    }

    useEffect(() => {
        getPrice()
    },[])

    const getPrice =  async () => {
        let prices = await axios.get(`${config.priceAPI}`, {
            params:{
                vs_currency: 'usd',
                order: 'market_cap_desc',
                per_page: 15,
                page:1,
                sparkline: false
            }
        })
        .then(response => {
            //console.log('PPPP',response)
            setTokenPrices(response.data)
        })
        .catch(error => {
            console.log('Error fetching prices',error)
        })
    }

    return (
        <>
            <section className="banner">
                <Swiper
                    direction={'vertical'}
                    loop={true}
                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: false
                    }}
                    modules={[Autoplay]}
                >
                    <SwiperSlide>
                        <img src="images/banner/InfiBanner.png" alt="" className="banner_img" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="images/banner/InfiBannerimage2.jpg" alt="" className="banner_img" />
                    </SwiperSlide>
                </Swiper>
                <Container>
                    <div className="banner_content">
                        <h5 className="fs-3 text-white">Create, Trade & Launch <span className="fs-1">
                            <span style={{ color: 'var(--infi-bg2)' }}>.</span><span style={{ color: 'var(--infi-bg1)' }}>.</span><span style={{ color: 'var(--infi-bg)' }}>.</span>
                        </span></h5>

                        <p className="mb-0"
                            style={
                                { fontSize: '31px', fontWeight: '300' }
                            }
                        >Launch a Project on</p>

                        <h3 className="fs-2 text-white"><span className="clr_1">INFI</span> <span className="clr_2">MULTICHAIN</span> <span className="clr_3">Launchpad</span> Now!
                        </h3>

                        <p className="fs-5" style={{ fontWeight: '300' }}>
                            INFI MultiChain Launchpad are plaform that help and advise <br /> project teams on how to best issue and launch their token.
                        </p>

                        <div className="">
                            <Button variant='outline-light me-3 d-inline-block mb-3' onClick={applicationForm}>RAISE FUNDS</Button>
                            <Button variant='outline-light me-3 text-nowrap d-inline-block mb-3' onClick={liquidityApplicationForm}>LIST YOUR PROJECT</Button>
                            {/* <Button variant='outline-light me-3 text-nowrap d-inline-block mb-3' onClick={infiApplicationForm}>SPECIAL INFI LISTING </Button> */}
                            <Button
                                            variant="outline-light me-3 text-nowrap d-inline-block mb-3 position-relative"
                                            onClick={infiApplicationForm}
                                            disabled
                                            style={{
                                                background: "rgba(255, 255, 255, 0.2)", 
                                                backdropFilter: "blur(5px)",           
                                                cursor: "not-allowed",                
                                            }}
                                        >
                                            <span style={{ opacity: 0.5 }}>SPECIAL INFI LISTING</span>
                                            <div
                                                className="coming-soon-overlay"
                                                style={{
                                                    position: "absolute",
                                                    top: 0,
                                                    left: 0,
                                                    width: "100%",
                                                    height: "100%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    background: "rgba(0, 0, 0, 0.5)", 
                                                    color: "#fff",
                                                    fontSize: "1rem",
                                                    fontWeight: "bold",
                                                    pointerEvents: "none",
                                                }}
                                            >
                                                Coming Soon
                                            </div>
                                    </Button>

                        </div>
                    </div>
                </Container>
            </section>
            <section className="bannerPrice_section">
                <Zoom>
                    <Marquee>
                        {tokenPrices.length > 0 && tokenPrices.map((token, index) => (
                            <a href="#" key={token.id} className="text-decoration-none text-black my-3">
                                <div className="flex flex-row gap-3 py-3 px-4 items-baseline w-full hover:bg-[#4A287E] hover:text-white">
                                    <p className="mb-0"><small>{token.name.toUpperCase()}</small></p>
                                    <div className="flex flex-row gap-1 items-baseline">
                                        <span className=""><b>${token.current_price.toLocaleString()}</b></span>
                                        <p className="w-full flex flex-row align-items-center font-medium text-sm mb-0" style={{ color: token.price_change_percentage_24h > 0 ? "rgb(38, 192, 117)" : "red" }}>
                                            <svg className="z-10" viewBox="0 0 1408 1024" style={{ display: "inline-block", stroke: "currentcolor", fill: "currentcolor", width: "10px", height: "10px", marginRight: "4px" }}>
                                                <path d={token.price_change_percentage_24h > 0 ? "M235.913 1024c-195.341 0-302.052-227.832-176.998-377.898l468.086-561.704c92.113-110.535 261.884-110.535 353.997-0l468.083 561.703c125.056 150.066 18.342 377.9-176.996 377.9h-936.173z" : "M1172.09 0c195.34 0 302.05 227.832 177 377.898L880.91 939.602c-92.11 110.535-261.88 110.535-354 0L58.83 377.898C-66.226 227.832 40.486 0 235.83 0h936.26z"} />
                                            </svg>
                                            {parseFloat(token.price_change_percentage_24h).toFixed(2)}%
                                        </p>
                                    </div>
                                </div>
                            </a>
                        ))}
                    </Marquee>
                </Zoom>
            </section>
            <section>
                    <div
            style={{
                position: 'relative',
                background: 'linear-gradient(90deg, #ADD8E6, #87CEEB)', 
                color: 'white',
                padding: '15px 0',
                overflow: 'hidden', 
                marginTop: '10px',
                borderRadius: '8px',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                textAlign: 'center',
            }}
        >
            <div
                style={{
                    display: 'inline-block',
                    whiteSpace: 'nowrap',
                    animation: 'scrollBanner 10s linear infinite',
                    fontSize: '1.8rem',
                    fontWeight: 'bold',
                    color: 'black', 

                }}
            >
                🚀 Coming Soon INFI/USDT Token Pair!!🚀 Be Ready for Something Exciting 🚀
            </div>
        </div>

        <style>
            {`
            @keyframes scrollBanner {
                0% {
                    transform: translateX(100%);
                }
                100% {
                    transform: translateX(-100%);
                }
            }
            `}
        </style>

            </section>
        </>
    )
}