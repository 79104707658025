import React from "react";
import { Button, Col, Container, Form, Row, Modal } from "react-bootstrap";
import { FiEye } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import config from "../../config";
import { loginSchema } from "./Loginschema";
import { useEffect, useState } from "react";
import { Formik } from "formik";
import { loginAction } from "../../Action/action";
import toast, { Toaster } from "react-hot-toast";
import { FaEyeSlash } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import * as ACTIONTYPES from "../../redux/actionTypes"
import { error } from "ajv/dist/vocabularies/applicator/dependencies";

export default function Login() {

  const dispatch = useDispatch();
  const loginDetails = useSelector((state) => state.auth.LoginDetails);

  const [formData, setForm] = useState({ email: "", password: "" });
  const [password, setshowPassword] = useState(false);
  const navigate = useNavigate();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isChecked, setIsChecked] = useState(false);


  useEffect(() => {
    if (loginDetails)
      checkValidity()

  }, [])

  const checkValidity = async () => {
    try {
      if (loginDetails.isLogin)
        navigate(`${config.baseUrl}home`);
      window.scrollTo(0,0)
    } catch (error) {
      console.log(error)

    }
  }

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleContinue = async() => {
    await navigate(`${config.baseUrl}forgetAddress`);
    window.scrollTo(0,0)
  }

  let handleSubmit = async (values, e) => {
try {
    let res = await loginAction(values, loginDetails);
    if (res.success) {
      //console.log(JSON.stringify(res.data))
      dispatch({
        type: ACTIONTYPES.USERLOGIN,
        payload: res.data,
        token: res.data.token,
        isLogin: true
      });
      dispatch({
        type: ACTIONTYPES.walletConnnected,
        payload: false,
      });
      toast.success(res.msg);
      setTimeout(() => {
        navigate(`${config.baseUrl}home`);
        window.scrollTo(0,0)
      }, 2000);
    } else {
      toast.error(res.msg);
      console.log('LOGIN ERROR',error)
    }
  } catch (error) {
    console.log(error)
  }
}
  const handleToggleVisibility = () => {
    setshowPassword(!password);
  };


  return (
    <>
      <Toaster />
      <section className="login">
        <Container fluid className="p-0">
          <Row className="mx-0" style={{ height: "100vh" }}>
            <Col lg={6} className="p-0 d-none d-lg-block">
              <div className="lg_img">
                <img
                  src="images/loginBanner.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </Col>
            <Col lg={6} className="p-0 mt-4 mt-lg-0">
              <div className="content_wrapper">
                <Container className=" text-center px-3">
                  <Row className="justify-content-center">
                    <Col lg={7}>
                      <h1>Welcome Back</h1>
                      <p>
                        Don't have an account?
                        <Link
                          to={`${config.baseUrl}`}
                          style={{ textDecoration: "none" }}
                        >
                          {" "}
                          <span style={{ color: "var(--infi-bg)" }}>
                            Sign Up
                          </span>{" "}
                        </Link>
                      </p>
                      <div className="form">
                        <Formik
                          initialValues={formData}
                          validationSchema={loginSchema}
                          onSubmit={handleSubmit}
                        >
                          {({
                            errors,
                            handleSubmit,
                            handleBlur,
                            handleChange,
                            touched,
                            values,
                          }) => (
                            <Form onSubmit={(e) => handleSubmit(e)}>
                              <Form.Control
                                type="text"
                                placeholder="Email"
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                              />
                              {errors.email && touched.email && (
                                <div
                                  className="text-danger"
                                  style={{ textAlign: "left" }}
                                >
                                  {errors.email}
                                </div>
                              )}

                              <div className="position-relative">
                                <Form.Control
                                  type={password ? "text" : "password"}
                                  className="mt-4"
                                  placeholder="Password"
                                  name="password"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.password}
                                />
                                <span
                                  className="position-absolute eye"
                                  onClick={handleToggleVisibility}
                                >
                                  {password ? <FaEyeSlash /> : <FiEye />}
                                </span>
                                {errors.password && touched.password && (
                                  <div
                                    className="text-danger"
                                    style={{ textAlign: "left" }}
                                  >
                                    {errors.password}
                                  </div>
                                )}
                              </div>

                              <Button
                                variant="primary"
                                className="mt-4 mb-2 w-100 "
                                type="submit"
                              >
                                Sign In
                              </Button>
                              <div className="text-end ">
                                <a href={`${config.baseUrl}forgetpassword`} className="float-end"> Forgot Password?</a>

                                <a href="#" onClick={handleShow} className="float-start"> Change Wallet Address?</a>
                              </div>

                            </Form>
                          )}
                        </Formik>
                      </div>
                    </Col>
                  </Row>
                </Container>

              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton >
          <h3 className="mb-0">Warning!</h3>
        </Modal.Header>
        <Modal.Body className="">
          <div className="">
            <div>
              <p className="mb-3"> You are about change your wallet address. Please note ©INFI MultiChain is responsible for the funds in your previous wallet. Please make sure you transfer your funds before changing the wallet address.</p>
              <div className="d-flex "> 
                <div className="pb-1 pe-1">
                  <input
                    type="checkbox"
                    className=""
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                </div>

                <Form.Label className="pb-0 mb-0 text-start">
                  I agree to the{" "}
                  <a href="/Docs/Privacy Policy.pdf" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and{" "}
                  <a href="./Docs/Terms & Conditions.pdf" target="_blank" rel="noopener noreferrer">Terms of Service</a>
                </Form.Label>
              </div>
            </div>

          </div>

        </Modal.Body>
        <Modal.Footer>
          <div className="text-end">
            <Button 
            variant="primary" 
            onClick={handleContinue}
            disabled={!isChecked}
            >
              Continue
            </Button>
          </div>
        </Modal.Footer>

      </Modal>
    </>
  );
}
