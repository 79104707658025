const { useWalletClient, useAccount } = require("wagmi")

const Web3WalletProvider = async() => {
    // const _useWeb3ModalProvider = useWalletClient()
    // return _useWeb3ModalProvider.data;

    const _useAccount = await useAccount()
        //console.log('acha',_useAccount.connector)
    // exports.provider = async () => {
        if(_useAccount.connector) {
        const data = await _useAccount.connector?.getProvider()
        return data
        }
        
    // }
}
export default Web3WalletProvider;