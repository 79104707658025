import React, { useState, useEffect,useRef } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { getActiveChains,updateApplicationFrom,getFormDetailsById,changeListingStatus } from "../../Action/action";
import { checkContractOnChain } from "../../Web3/web3FunctionCall";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate,useParams } from "react-router-dom";
import config from "../../config";
import Web3WalletProvider from '../../ConnectWallet/walletProvider'
import { ApproveToken, NewSale } from "../../Web3/Connector";
import { useDisconnect, useAccount } from "wagmi";
import Web3 from "web3";
import "../Detail/detail.css"
import { showLoader,hideLoader } from "../loader";

export default function LaunchListingForm() {
  const {id} = useParams()

  const [form, setForm] = useState([]);
  
  const [update, setUpdate] = useState(false);

  const [errors, setErrors] = useState({});
  const [chainOptions, setChainOptions] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);

  const [getRpc, setRpc] = useState();
  const dispatch = useDispatch();
  const loginDetails = useSelector((state) => state.auth.LoginDetails);
  const navigate = useNavigate();

  let _useWeb3WalletProvider = Web3WalletProvider()
  const walletConnnected = useSelector((state) => state.auth.walletConnnected);
  const [isProviderLoaded, setisProviderLoaded] = useState(false);
  const [presaleLoading, setPresaleLoading] = useState(false)
  const {address, isConnected} = useAccount()
  const _useDisconnect = useDisconnect()
  const [connectWalletNow, setconnectWalletNow] = useState(false);
  const [Waddress, setAddress] = useState("");

  const [fields, setFields] = useState([{name:""}])


  const handleFieldChange = (index,event) => {    const newFields = [...fields];
    newFields[index].name = event.target.value;
    setFields(newFields);
  };




useEffect(() => {
  console.log(isConnected, "IsConnect")
  console.log(address, "ADDRESS")
  console.log(walletConnnected, "WALLET")

  if(walletConnnected) {
    setconnectWalletNow(false)
  }
  setAddress(address)
},[walletConnnected])

const disconnectWallet = () => {
  _useDisconnect.disconnect()
}

useEffect(() => {
  if(!isProviderLoaded) {_useWeb3WalletProvider.then((data) => {
    if(data) {
      setisProviderLoaded(true)
    }
  })}
},[_useWeb3WalletProvider])
const buttonRef = useRef(null); // Add a ref for the button

useEffect(() => {
  // AutoBNBally click the button after 2 seconds
  const timer = setTimeout(() => {
    if (buttonRef.current) {
      buttonRef.current.click();
    }
  }, 2000);

  return () => clearTimeout(timer); // Cleanup the timer
}, []);




  useEffect(() => {
    getChains();
    getFormData()
  }, []);

  const getChains = async () => {
    try {
      const response = await getActiveChains(loginDetails);
      if (response.success) {
        console.log('cccc',response)
        setChainOptions(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getFormData = async () => {
    try {
      const response = await getFormDetailsById({id},loginDetails);
      console.log('FORM',response)
      if (response.success) {
        response.data[0].social_accounts= JSON.parse(response.data[0].social_accounts)
        setForm(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };



  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log({name, value})
    if (name == "chainName") {
      for (let i = 0; i < chainOptions.length; i++) {
        if (chainOptions[i].id == value) {
          setRpc(chainOptions[i].chainRPCUrl);
        }
      }
    }

    const index = 0
    setForm(prevForm => {
      const newForm = [...prevForm];
      newForm[index] = { ...newForm[index], [name]: value };
      return newForm;
    });
  };

  const handleFileChange = (e) => {

    const index = 0
    console.log(e.target.files[0])
    setForm(prevForm => {
      const newForm = [...prevForm];
      newForm[index] = { ...newForm[index], brand_logo: e.target.files[0]};
      return newForm;
    });
    setImagePreview(URL.createObjectURL(e.target.files[0]));
  };

  const handleSocialAccountChange = (index, e) => {
    const { name, value } = e.target;
    const socialAccounts = [...form[0].social_accounts];
    socialAccounts[index][name] = value;
    const ind = 0
    setForm(prevForm => {
      const newForm = [...prevForm];
      newForm[ind] = { ...newForm[ind], socialAccounts};
      return newForm;
    });
  };

  const addSocialAccount = () => {
    const ind = 0
    setForm(prevForm => {
      const newForm = [...prevForm];
      newForm[ind] = { ...newForm[ind],social_accounts: [...form[ind].social_accounts, { name: "", link: "" }]};
      return newForm;
    });
  };

// const chartStyle = {
//     maxWidth: '400px',
//     margin: '0 auto',
//     padding: '20px',
//     backgroundColor: '#f9f9f9',
//     borderRadius: '10px',
//     boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//   };
 

  const validateForm = async () => {
    const newErrors = {};
    const urlPattern =
      /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
      console.log(form)

    if (!form[0].token_name) newErrors.token_name = "Token Name is required";

    if (!form[0].token_address)
      newErrors.token_address = "Token Address is required";
    if (!form[0].description) newErrors.description = "Description is required";
    if (!form[0].duration) newErrors.duration = "Duration is required";
    if (!form[0].brand_logo) newErrors.brand_logo = "Logo is required";
    if (!form[0].kyc) newErrors.kyc = "KYC is required";
    if (!form[0].audit) newErrors.audit = "Audit is required";
    if (!form[0].liquidity)
      newErrors.liquidity = "Liquidity value is required";
    if (!form[0].private_sale)
      newErrors.private_sale = "Private Sale value is required";
    if (!form[0].ico) newErrors.ico = "ICO value is required";
    if (!form.team) newErrors.team = "Team value is required";
    if (!form.partnership) newErrors.partnership = "Partnership value is required";
    if (!form.others) newErrors.others = "Value is required";
    if (!form.hardCap) newErrors.hardCap = "Hard cap value is required";
  
    if (!form[0].token_amount
)
      newErrors.token_amount
 = "Token amount value is required";
    
    if (!form[0].usdt_price) newErrors.usdt_price = "USDT price is required";
    
    //if (!form[0].native_price) newErrors.native_price = "Native price is required";
   
    let result = await checkContractOnChain(getRpc, form[0].token_address);

    if (!result.status) newErrors.token_address = `${result.msg}`;
   
    const socialLinks = ["website", "telegram", "youtube", "linkedin"];
    socialLinks.forEach((field) => {
      if (form[0][field] && !urlPattern.test(form[0][field])) {
        newErrors[field] = `Invalid URL format for ${field}`;
      }
    });
    console.log(newErrors)
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const handleSubmitPresale = async (e) => {
    e.preventDefault();

    if(!isConnected) {
      toast.error("Please connect with the registered wallet address.");
      setconnectWalletNow(true)
      return
    }
    if(address.toUpperCase() != loginDetails.walletAddress.toUpperCase()) {
      toast.error("Please connect with registered wallet address.")
      return
    }
    setPresaleLoading(true)
    showLoader()
    try {
    let request = {
      walletProvider:  _useWeb3WalletProvider,
      tokenOwner: address,
      tokenAddress:form[0].token_address,
      tokenAmount:form[0].token_amount,
      UsdtPrice:form[0].usdt_price,
      preSaleAddress:form[0].presaleContract,
      usdtAddress:form[0].usdtContract,
      duration:form[0].duration,
      networkId:form[0].chainNetworkId
    }

    let approval = await ApproveToken(request)

//    console.log('APPP',request)

    
    if (approval.status) {
      let res = await NewSale(request)
      //console.log('FORMRES',res)
      if (res.status) {
        toast.success(res.message)
        setTimeout(async () => {
            let list = await changeListingStatus({id},loginDetails)
            console.log('LISTED',list)
            if (list.success) {
            navigate(`${config.baseUrl}home`)
            window.scrollTo(0,0)
        }
      } ,5000)
    } else {
      toast.error(res.message)
        setPresaleLoading(false)
      }
    } else {
      toast.error(approval.message)
      setPresaleLoading(false)
    }
} catch (error) {
  setPresaleLoading(false)
  console.log('Error Presale',error)
  alert('Presale Error',error)
} finally {
  hideLoader()
}

}
  return (
    <>
      <Toaster />
      <Header />
      <section className="process_form">
        <Container>
       
         {form.length > 0 && <Form >
            <div className="from_box my-5">
              <h2>The Launch process:</h2>
              <p>
                Must be confirmed with the Owner wallet (the tokens will be
                transferred to the INFI CDEX Launchpool)
              </p>
              <Row className="mt-4">
                <Col lg={6}>
                  <Form.Label>Token Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="token_name"
                    value={form[0].token_name}
                    onChange={handleInputChange}
                    isInvalid={!!errors.token_name}
                    disabled={!update?true:false}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.token_name}
                  </Form.Control.Feedback>
                </Col>

                <Col lg={6}>
                  <Form.Label>Token Address</Form.Label>
                  <Form.Control
                    type="text"
                    name="token_address"
                    value={form[0].token_address}
                    onChange={handleInputChange}
                    isInvalid={!!errors.token_address}
                    disabled={!update?true:false}

                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.token_address}
                  </Form.Control.Feedback>
                </Col>

                <Col lg={6}>
                  <Form.Label>Chain Network</Form.Label>
                  <Form.Control
                    as="select"
                    name="chainName"
                    value={form[0].chainName}
                    onChange={handleInputChange}
                    isInvalid={!!errors.chainName}
                    disabled={!update?true:false}

                  >
                    {/* {console.log(form[0].chainName)}
                    {console.log(chainOptions)} */}
                    {chainOptions.map((option, index) => (
                      <option key={index} value={option.chainName}>
                        {option.chainName}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.chainName}
                  </Form.Control.Feedback>
                </Col>

                <Col lg={12} className="mt-4">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="description"
                    placeholder="max 1000 characters"
                    value={form[0].description}
                    onChange={handleInputChange}
                    isInvalid={!!errors.description}
                    disabled={!update?true:false}

                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.description}
                  </Form.Control.Feedback>
                </Col>

                {update?<Col lg={12} className="mt-4">
                  <Form.Label>ADD a Brand Logo/Upload a Logo</Form.Label>
                  <div className="position-relative">
                    <Form.Control
                      type="file"
                      className="custom_dragdrop_form"
                      onChange={handleFileChange}
                      isInvalid={!!errors.brand_logo}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.brand_logo}
                    </Form.Control.Feedback>
                    <div className="custom_dragdrop my-3">
                      <span>
                        Click to upload files, or drag & drop files here
                      </span>
                    </div>
                    {imagePreview && (
                      <div className="image-preview mt-3">
                        <img
                          src={imagePreview}
                          alt="Preview"
                          style={{ maxHeight: "200px", maxWidth: "100%" }}
                        />
                      </div>
                    )}
                  </div>
                </Col>:
                <Col lg={12} className="mt-4">
                <Form.Label className="me-2">Brand Logo</Form.Label>
               
                  
                      <img
                        src={`${config.imagePath}${form[0].brand_logo}`}
                       // alt="Preview"
                        style={{ maxHeight: "200px", maxWidth: "100%" }}
                      />
               
              </Col>}

                <Col lg={6} className="mt-4">
                  <Form.Label>KYC</Form.Label>
                  <Form.Control
                    type="text"
                    name="kyc"
                    value={form[0].kyc}
                    onChange={handleInputChange}
                    isInvalid={!!errors.kyc}
                    disabled={!update?true:false}

                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.kyc}
                  </Form.Control.Feedback>
                </Col>

                <Col lg={6} className="mt-4">
                  <Form.Label>Audit</Form.Label>
                  <Form.Control
                    type="text"
                    name="audit"
                    value={form[0].audit}
                    onChange={handleInputChange}
                    isInvalid={!!errors.audit}
                    disabled={!update?true:false}

                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.audit}
                  </Form.Control.Feedback>
                </Col>

                <Col lg={12} className="mt-4">
                  <Form.Label>Social Platform</Form.Label>
                  {fields.map((field, index) =>(
                    <div key={index}>
                      <Form.Control
                        type="text"
                        name="website"
                        placeholder="Website (if any)"
                        value={form[0].website}
                        onChange={(event) => handleFieldChange(index, event)}
                        disabled={!update?true:false}
                        />
                    </div>
                  ))}
                  {/* <div className="text-end">
                    <Button variant="add" onClick={handleAddField} className="p-0">
                      + Add more
                    </Button>
                  </div> */}
                </Col>
              </Row>

              <Row className="align-items-end">
                <Col lg={6} className="mb-3">
                  <Form.Label>Presale Hard Cap</Form.Label>
                  <Form.Control
                    type="text"
                    name="hardCap"
                    placeholder="Value"
                    value={form[0].hard_cap}
                    onChange={handleInputChange}
                    isInvalid={!!errors.hardCap}
                    disabled={!update?true:false}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.hardCap}
                  </Form.Control.Feedback>
                </Col>
                <Col lg={6} className="mb-3">
                </Col>
                <Col lg={6} className="mb-3">
                  
                  <Form.Label>Presale Soft Cap</Form.Label>
                  {/* <InputGroup className="mb-3"> */}
                    <Form.Control aria-label="Text input with dropdown button"
                    type="text"
                    name="softCap"
                    placeholder="Value"
                    value={form[0].soft_cap}
                    onChange={handleInputChange}
                    isInvalid={!!errors.softCap}
                    disabled={!update?true:false}
                    />
                </Col>
              </Row>


              <Row className="mt-4">
                <Form.Label>Tokenomics</Form.Label>
                <Col lg={6}>
                  <Form.Label>Total Supply</Form.Label>
                 
                </Col>

                <Col lg={6}>
                  <Form.Control
                    type="text"
                    name="liquidity"
                    placeholder="Total Supply"
                    value={form[0].liquidity}
                    onChange={handleInputChange}
                    isInvalid={!!errors.liquidity}
                    disabled={!update?true:false}

                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.liquidity}
                  </Form.Control.Feedback>
                </Col>

                <Col lg={6} className="mt-4">
                  <Form.Label>Private Sale</Form.Label>
                 
                </Col>

                <Col lg={6} className="mt-4">
                  <Form.Control
                    type="text"
                    name="private_sale"
                    placeholder="Value (In % percentage)"
                    value={form[0].private_sale}
                    onChange={handleInputChange}
                    isInvalid={!!errors.private_sale}
                    disabled={!update?true:false}

                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.private_sale}
                  </Form.Control.Feedback>
                </Col>

                <Col lg={6} className="mt-4">
                  <Form.Label>ICO</Form.Label>
                 
                </Col>

                <Col lg={6} className="mt-4">
                  <Form.Control
                    type="text"
                    name="ico"
                    placeholder="Value (In % percentage)"
                    value={form[0].ico}
                    onChange={handleInputChange}
                    isInvalid={!!errors.ico}
                    disabled={!update?true:false}

                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.ico}
                  </Form.Control.Feedback>
                </Col>

                <Col lg={6} className="mt-4">
                  <Form.Label>Team</Form.Label>
                 
                </Col>

                <Col lg={6} className="mt-4">
                  <Form.Control
                    type="text"
                    name="team"
                    placeholder="Value (In % percentage)"
                    value={form[0].team}
                    onChange={handleInputChange}
                    isInvalid={!!errors.team}
                    disabled={!update?true:false}

                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.team}
                  </Form.Control.Feedback>
                </Col>

                <Col lg={6} className="mt-4">
                  <Form.Label>Partnerships</Form.Label>
                 
                </Col>

                <Col lg={6} className="mt-4">
                  <Form.Control
                    type="text"
                    name="partnerships"
                    placeholder="Value (In % percentage)"
                    value={form[0].partnerships}
                    onChange={handleInputChange}
                    isInvalid={!!errors.partnerships}
                    disabled={!update?true:false}

                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.partnerships}
                  </Form.Control.Feedback>
                </Col>

                <Col lg={6} className="mt-4">
                  <Form.Label>Others</Form.Label>
                 
                </Col>

                <Col lg={6} className="mt-4">
                  <Form.Control
                    type="text"
                    name="others"
                    placeholder="Value (In % percentage)"
                    value={form[0].others}
                    onChange={handleInputChange}
                    isInvalid={!!errors.others}
                    disabled={!update?true:false}

                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.others}
                  </Form.Control.Feedback>
                </Col>

                <Col lg={6} className="mt-4">
                  <Form.Label>Token Amount For Presale </Form.Label>
                </Col>

                <Col lg={6} className="mt-4">
                  <Form.Control
                    type="text"
                    name="token_amount"
                    placeholder="Value"
                    value={form[0].token_amount}
                    onChange={handleInputChange}
                    isInvalid={!!errors.token_amount}
                    disabled={!update?true:false}

                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.token_amount}
                  </Form.Control.Feedback>
                </Col>

                <Col lg={6} className="mt-4">
                  <Form.Label>USDT price</Form.Label>
                </Col>

                <Col lg={6} className="mt-4">
                  <Form.Control
                    type="text"
                    name="usdt_price"
                    placeholder="Value"
                    value={form[0].usdt_price}
                    onChange={handleInputChange}
                    disabled={!update?true:false}
                    isInvalid={!!errors.usdt_price}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.usdt_price}
                  </Form.Control.Feedback>
                </Col>

                {/* <Col lg={6} className="mt-4">
                  <Form.Label>Native price</Form.Label>
                </Col>

                <Col lg={6} className="mt-4">
                  <Form.Control
                    type="text"
                    name="native_price"
                    placeholder="Value"
                    value={form[0].native_price}
                    onChange={handleInputChange}
                    isInvalid={!!errors.native_price}
                    disabled={!update?true:false}

                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.native_price}
                  </Form.Control.Feedback>
                </Col> */}

                <Col lg={6} className="mt-4">
                  <Form.Label>Duration</Form.Label>
                </Col>

                <Col lg={6} className="mt-4">
                  <Form.Control
                    type="text"
                    name="duration"
                    placeholder="In Days"
                    value={form[0].duration}
                    onChange={handleInputChange}
                    isInvalid={!!errors.duration}
                    disabled={!update?true:false}

                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.duration}
                  </Form.Control.Feedback>
                </Col>

              </Row>

              <Row className="mt-4">
                <Col lg={12}>
                  <Form.Label>Whitepaper</Form.Label>
                  <Form.Control
                    type="text"
                    name="whitepaper"
                    placeholder="Add a whitepaper link here."
                    value={form[0].whitepaper}
                    onChange={handleInputChange}
                    disabled={!update?true:false}

                  />
                </Col>

                <Form.Label className="mt-4">
                  Project Social Accounts (Up to 10 accounts)
                </Form.Label>
               
                {form[0].social_accounts.map((account, index) => (
                  <React.Fragment key={index}>
                    <Col lg={6} className="mb-4">
                      <Form.Control
                        type="text"
                        name="name"
                        placeholder="Social Account name"
                        value={account.name}
                        onChange={(e) => handleSocialAccountChange(index, e)}
                    disabled={!update?true:false}

                      />
                    </Col>
                    <Col lg={6} className="mb-4">
                      <Form.Control
                        type="text"
                        name="link"
                        placeholder="Add a link here."
                        value={account.link}
                        onChange={(e) => handleSocialAccountChange(index, e)}
                    disabled={!update?true:false}

                      />
                    </Col>
                  </React.Fragment>
                ))}
                {update && <div className="text-end">
                  <Button variant="add" onClick={addSocialAccount}>
                    + Add more
                  </Button>
                </div>}
              </Row>

              <hr />

              <div className="mt-4 text-center">
              <Button variant="dark" className="px-4 me-2 py-2"onClick={(e)=>handleSubmitPresale(e)} >
                  Deploy Project
                </Button>
                
                {/* <Button variant="dark" className="px-4 py-2"onClick={()=>setUpdate(true)} >
                  Update
                </Button> */}
                <p className="mt-2">
                  Never submit password or credit card details through workform
                </p>
              </div>
            </div>
          </Form>}
        </Container>
      </section>
      <Footer />
    </>
  );
}
