const config2 = {

//     baseUrl: '/infilaunchpad/',
//   // serverPath: 'https://espsofttech.org:4321/users',
//   // imagePath: 'https://espsofttech.org:4321/users/uploads/',
//  serverPath: 'http://localhost:8000/users',
// imagePath: 'http://localhost:8000/users/uploads/',
baseUrl: '/',
serverPath: "https://launchpad.infimultichain.com/users",
imagePath: "https://launchpad.infimultichain.com/media", 
priceAPI: 'https://api.coingecko.com/api/v3/coins/markets'
}

export default config2;