import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import config from '../../config';
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  InputGroup,
} from 'react-bootstrap';

import { Spin } from 'antd';
import { changePasswordAction } from '../../Action/action'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';


export default function ChangePassword() {
  const location = useLocation()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showLoading, setShowLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmpassword, setconfirmPassword] = useState('');
  const [otp, setotp] = useState('');

  const forgetEmails = useSelector((state) => state.auth.ForgetEmail);
  const [showpass, setshowpass] = useState(false);
  const email = location.state?.email || ''


  const submitForm = async (e) => {
    e.preventDefault()
    setShowLoading(true)
    if (password !== confirmpassword) {
      setShowLoading(false);
      toast.error('Password do not match');
      return;
    }
    if (!password || !confirmpassword || !otp) {
      setShowLoading(false);
      toast.error('Please enter required fields');
      return;
    }
    try {
    let data = {
      emailAddress: email,
      newPassword: password,
      confirmPassword: confirmpassword,
      otp: otp,
    }

    let res = await changePasswordAction(data);
    console.log('dd',res)
    if (res.success) {
      toast.success(res.msg);
      navigate(`${config.baseUrl}login`);
      window.scrollTo(0,0)
      return;
    } else {
      toast.error(res.msg);
      return;
    } } catch(error) {
      toast.error('Something went wrong. Please try again.');
    } finally {
      setShowLoading(false);
    }
  };

  const inputhandler = (e) => {
    const {name, value} = e.target
    if (name === 'password') {
      setPassword(value);
    } else if (name === 'confirmpassword') {
      setconfirmPassword(value);
    } else if (name === 'otp') {
      setotp(value);
    }
  };


  return (
    <>
    <Toaster/>
      <section className="login-section ">
        <div className="login-container">
          <Container fluid className="">
                <Row className=" justify-content-center">
                 
                  <Col lg={7} md={7} xs={12} className="">
                    <Spin spinning={showLoading}>
                      <div className="loginDiv pt-5 mt-5">
                        <Row>
                          <Col lg={7} className="mx-auto">
                            <div className="form_content border p-4 rounded-5">
                              <div className="formDiv">
                                <div className="text-center formheadbox">
                                  <h1 className=" mb-1">
                                    Reset Password
                                  </h1>
                                  <p className="">
                                    <small>Enter new password </small>
                                  </p>
                                </div>
                                <div className="mb-3">
                                  <div className="input-field fieldbox px-0">
                                    <InputGroup className=" theme-input">
                                      <Form.Control
                                        type={`${
                                          showpass ? 'text' : 'password'
                                        }`}
                                        placeholder="New Password"
                                        aria-describedby="basic-addon1"
                                        onChange={inputhandler}
                                        name="password"
                                        className="py-2 "
                                      />
                                    </InputGroup>
                                  </div>
                                </div>

                                <div className="mb-3">
                                  <div className="input-field fieldbox px-0">
                                    <InputGroup className=" theme-input">
                                      <Form.Control
                                        type={`${
                                          showpass ? 'text' : 'password'
                                        }`}
                                        placeholder="Confirm Password"
                                        aria-describedby="basic-addon1"
                                        name="confirmpassword"
                                        onChange={inputhandler}
                                        className="py-2 "
                                      />
                                    </InputGroup>
                                  </div>
                                </div>

                                <div className="mb-3">
                                  <div className="input-field fieldbox px-0">
                                    <InputGroup className=" theme-input">
                                      <Form.Control
                                        type="text"
                                        placeholder="Enter OTP"
                                        aria-describedby="basic-addon1"
                                        name="otp"
                                        onChange={inputhandler}
                                        className="py-2 "
                                      />
                                    </InputGroup>
                                  </div>
                                </div>

                                <div className="text-center mt-3 ">
                                  <Button
                                    variant="primary"
                                    className="py-2 w-100 fw-bold"
                                    type="button"
                                    onClick={submitForm}
                                  >
                                    Reset Password
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Spin>
                  </Col>
                </Row>
          </Container>
        </div>
      </section>
    </>
  );
};


