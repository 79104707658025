import React, { useEffect, useState, useRef } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Form, InputGroup, Button, Tab, Nav } from 'react-bootstrap';
import config from "../../config"
import "../Detail/detail.css"
import { getInfiFormDetailsById } from "../../Action/action"
import Web3WalletProvider from "../../ConnectWallet/walletProvider"
import { useDisconnect, useAccount } from "wagmi";
import { toast, Toaster } from 'react-hot-toast';
import { FaArrowRightLong } from "react-icons/fa6";
import ReadMore from "../Detail/readmore";
import { GrSync } from "react-icons/gr";
import { ApproveToken, getTokenPrice, PoolBalances,getUserTokenBalance, getUserInfiBalance, ApproveUsdtToken,ApproveInfiToken, ReceivableInfi, ReceivableToken, SwapTokenToInfi, SwapInfiToToken, getTokenPriceInfiSwap } from "../../Web3/Liquidity/infiSwapConnector"
import { showLoader,hideLoader } from "../loader";
import { setIn } from "formik";


export default function InfiDetail() {
    const { id } = useParams()
    const [form, setForm] = useState([]);
    const loginDetails = useSelector((state) => state.auth.LoginDetails);
    const walletConnnected = useSelector((state) => state.auth.walletConnnected);
    const navigate = useNavigate();
    const _useWeb3WalletProvider = Web3WalletProvider()
    const { address, isConnected } = useAccount()
    const [connectWalletNow, setconnectWalletNow] = useState(false);
    const [Waddress, setAddress] = useState("");

    // const [expectedUsdt, setExpectedUsdt] = useState({});
    // const [expectedToken, setExpectedToken] = useState({});
    const [swapClick, setSwapClick] = useState(false)
    const _useDisconnect = useDisconnect()
    const [isProviderLoaded, setisProviderLoaded] = useState(false);


    const [swapLoading, setSwapLoading] = useState(false);
    const [infi, setInfi] = useState(0);
    const [token, setToken] = useState(0);
    const [isInfiFirst, setIsInfiFirst] = useState(true)
    const [error, setError] = useState("");

    useEffect(() => {
    const customStyles = {
      
    };

    // Apply custom styles to the body
    for (const [key, value] of Object.entries(customStyles)) {
      document.body.style[key] = value;
    }

    // Cleanup function to reset styles when component unmounts (optional)
    return () => {
      for (const key of Object.keys(customStyles)) {
        document.body.style[key] = "";
      }
    };
  }, []);

    useEffect(() => {

        if (walletConnnected) {
            setconnectWalletNow(false)
        }
        setAddress(address)
    }, [walletConnnected])

    const disconnectWallet = () => {
        _useDisconnect.disconnect()
    }

    useEffect(() => {
        if (!isProviderLoaded) {
            _useWeb3WalletProvider.then((data) => {
                if (data) {
                    setisProviderLoaded(true)
                }
            })
        }
    }, [_useWeb3WalletProvider])
    const buttonRef = useRef(null); // Add a ref for the button

    useEffect(() => {
        // AutoBNBally click the button after 2 seconds
        const timer = setTimeout(() => {
            if (buttonRef.current) {
                buttonRef.current.click();
            }
        }, 2000);

        return () => clearTimeout(timer); // Cleanup the timer
    }, []);


    useEffect(() => {
        if(isProviderLoaded) {
        getFormData()
        }
    }, [id,isProviderLoaded])

    const getFormData = async () => {
        try {
            const response = await getInfiFormDetailsById({ id });
            console.log('FORM', response)
            if (response.success) {
                if (response.data.length > 0) {

                    let pb = await PoolBalances(response.data)
                    response.data[0].BALANCES = pb.data[0]

                    let tp = await getTokenPriceInfiSwap(response.data)
                    //console.log("TP",response.data)
                    response.data[0].PRICE = tp.data[0]

                    let balInfi = await WalletInfiBalance(response.data[0])
                    response.data[0].BALANCEINFI = balInfi

                    let balToken = await WalletTokenBalance(response.data[0])
                    response.data[0].BALANCETOKEN = balToken

                    let iData = await ExpectedInfi(response.data[0], 0)
                    response.data[0].infiData = iData
                    console.log('IIII',iData)

                    let uToken = await ExpectedToken(response.data[0], 0)
                    response.data[0].tokenData = uToken
                    console.log('TTTT',uToken)

                }
                setForm(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    };


    const TokenPrice = async (formData) => {
        try {
            let request = {
                walletProvider: _useWeb3WalletProvider,
                tokenName: formData.token_name,
                infipairContractAddress: formData.infiPairContract,
                tokenAddress: formData.token_address,
                networkId: formData.chainNetworkId
            }
            console.log('REQ', request)
            let price = await getTokenPriceInfiSwap(request)
            console.log('PPP', price)
            return price.price

        } catch (error) {
            console.log(error)
        }
    }

    const WalletInfiBalance = async (formData) => {
        try {
            let request = {
                walletProvider: _useWeb3WalletProvider,
                infiAddress: formData.infiContract,
                networkId: formData.chainNetworkId
            }
            let infiBal = await getUserInfiBalance(request)
            return infiBal.balance
        } catch (error) {
            console.log("Error getting wallet infi balance", error)
        }
    }

    const WalletTokenBalance = async (formData) => {
        try {
            let request = {
                walletProvider: _useWeb3WalletProvider,
                tokenAddress: formData.token_address,
                networkId: formData.chainNetworkId
            }
            let tokenBal = await getUserTokenBalance(request)
            return tokenBal.balance
        } catch (error) {
            console.log("Error getting wallet token balance", error)
        }
    }

    const ExpectedInfi = async (formData, _token) => {
        try {
            let request = {
                walletProvider:_useWeb3WalletProvider,
                infipairContractAddress: formData.infiPairContract,
                tokenAddress: formData.token_address,
                tokenAmount: _token,
                networkId: formData.chainNetworkId
            }
            let res = await ReceivableInfi(request)
            if (res.length > 0) {
                return res[0].data
            }
        } catch (error) {
            console.log("Error getting expected infi", error)
        }
    }

    const ExpectedToken = async (formData, _infi) => {
        try {
            let request = {
                walletProvider:_useWeb3WalletProvider,
                infipairContractAddress: formData.infiPairContract,
                tokenAddress: formData.token_address,
                infiAddress: formData.infiContract,
                infiAmount: _infi,
                networkId: formData.chainNetworkId
            }
            let res = await ReceivableToken(request)
            console.log('EUR', res)
            if (res.length > 0) {
                return res[0].data
            } else {
               return toast.error(res.message)
            }
        } catch (error) {
            console.log("Error getting expected token", error)
        }
    }


    const poolTokenBalance = async (formData) => {
        let request = {
            walletProvider:_useWeb3WalletProvider,
            tokenAddress: formData.token_address,
            infipairContractAddress: formData.infiPairContract,
            networkId: formData.chainNetworkId,
        }
        const res = await PoolBalances(request)
        if (res.length > 0) {
            return (res[0].data)
        }
    }

    const handleInfiToTokenSwap = async () => {
        if(!isConnected) {
            toast.error("Please connect with the registered wallet address.");
            setconnectWalletNow(true)
            return
          }
          if(address.toUpperCase() != loginDetails.walletAddress.toUpperCase()) {
            toast.error("Please connect with registered wallet address.")
            return
          }
        try {
            setSwapLoading(true)
            showLoader()
            let usdt = (form.length > 0 ? form[0].tokenData.Fees : '')
            let request = {
                walletProvider: _useWeb3WalletProvider,
                infipairContractAddress: form.length > 0 ? form[0].infiPairContract : '',
                infiAddress: form.length > 0 ? form[0].infiContract : '',
                usdtAddress:  form.length > 0 ? form[0].usdtContract : '',
                usdtAmount: usdt,
                tokenAddress: form.length > 0 ? form[0].token_address : '',
                infiAmount: infi,
                networkId: form.length > 0 ? form[0].chainNetworkId : '',
            }
            console.log('R',request)

            let infiApp = await ApproveInfiToken(request)
            if (infiApp.status) {
                let usdtApp = await ApproveUsdtToken(request)
                if (usdtApp.status) {                
                    let swap = await SwapInfiToToken(request)
                    console.log('swapres', swap)
                    if (swap.status) {
                        toast.success(swap.message)
                    } else {
                        toast.error(swap.message)
                    }
                } else {
                    toast.error(usdtApp.message)
                    setInfi("")
                    setToken("")
                }
            } else {
                toast.error(infiApp.message)
                setInfi("")
                setToken("")
            }

        } catch (error) {
            console.log('infi to token Swap error', error)
            setInfi("")
            setToken("")

        } finally {
            hideLoader()
            setSwapLoading(false);
        }
    }

    const handleTokenToInfiSwap = async () => {
        if(!isConnected) {
            toast.error("Please connect with the registered wallet address.");
            setconnectWalletNow(true)
            return
          }
          if(address.toUpperCase() != loginDetails.walletAddress.toUpperCase()) {
            toast.error("Please connect with registered wallet address.")
            return
          }
        try {
            setSwapLoading(true)
            showLoader()
            let usdt = (form.length > 0 ? form[0].infiData.Fees : '')
            let request = {
                walletProvider: _useWeb3WalletProvider,
                infipairContractAddress: form.length > 0 ? form[0].infiPairContract : '',
                infiAddress: form.length > 0 ? form[0].infiContract : '',
                usdtAddress: form.length > 0 ? form[0].usdtContract: '',
                usdtAmount: usdt,
                tokenAddress: form.length > 0 ? form[0].token_address : '',
                tokenAmount: token,
                sbseAddress: form.length > 0 ? form[0].sbse_reserve : '',
                sbseAmount: form.length > 0 ? form[0].SBSE : '',
                networkId: form.length > 0 ? form[0].chainNetworkId : '',
            }
            let tokenApp = await ApproveToken(request)
            if (tokenApp.status) {
                let usdtApp = await ApproveUsdtToken(request)
                if (usdtApp.status) {
                    let swap = await SwapTokenToInfi(request)
                    if (swap.status) {
                        toast.success(swap.message)
                    } else {
                        toast.error(swap.message)
                    }
                  } else {
                    toast.error(usdtApp.message)
                    setInfi("")
                    setToken("")
                  }
                }

            else {
                toast.error(tokenApp.message)
                setInfi("")
                setToken("")

            }
        } catch (error) {
            console.log('token to infi Swap error', error)
            setInfi("")
            setToken("")
        } finally {
            hideLoader()
            setSwapLoading(false)
        }
    }

    const onchangehandlerInfi = async (e) => {
        e.preventDefault()
        let newInfi = e.target.value
        if(newInfi >= form[0].BALANCES.INFI) {
            setError(`Amount cannot exceed ${form[0].BALANCES.INFI} INFI.`);
            setInfi(newInfi);
            return
        } else {
            setError("")
        }
            setInfi(newInfi);
            let newForm = [...form]
            let res = await ExpectedToken(newForm[0], newInfi)
            newForm[0].tokenData = res
            setForm(newForm)
    }

    const onchangehandlerToken = async (e) => {
        e.preventDefault()
        let newToken = e.target.value
        if(newToken >= form[0].BALANCES.Token) {
            setError(`Amount cannot exceed ${form[0].BALANCES.Token} ${form[0].token_name}.`);
            setToken(newToken);
            return
        } else {
            setError("")
        }
        setToken(newToken)
        let updatedForm = [...form]
        let res = await ExpectedInfi(updatedForm[0], newToken)
        updatedForm[0].infiData = res
        let reserve = await calculateSbseReserve()
        updatedForm[0].SBSE = reserve
        setForm(updatedForm)

    }


    const calculateSbseReserve = async () => {
        const tokenLiquidityInInfi = (form[0].BALANCES.Token * form[0].PRICE)
        const output = form[0].infiData.Expected
        if (output <= 0.01 * tokenLiquidityInInfi) {
            return 0;
        }
        
        for (let i = 1; i <= 100; i++) {
            if (output >= (i / 100) * tokenLiquidityInInfi) {
                const result = (i * 0.03) * output;
                return result
            }
        }
    }




    const changeCoins = (e) => {
        // e.preventDefault()
        setError("")
        setToken("")
        setInfi("")
        setIsInfiFirst(!isInfiFirst)
    }

    const handleDashboard = async (e) => {
        e.preventDefault()
        navigate(`${config.baseUrl}dashboard/infiLiquidity/${id}`)
        window.scrollTo(0,0)
    }

    return (
        <>
            <Header />
            <Toaster></Toaster>
            <div className="detail process_form my-5 mt-4">
                <div className="infivideo">
                    <video autoPlay muted loop id="myVideo" playsInline>
                        <source src={`${config.baseUrl}images/banner/INFIBGVIDEO.mp4`} type="video/mp4" />
                        Your browser does not support HTML5 video.
                    </video>
                </div>
                <Container>
                    <Row>
                        <Col lg={12} className="text-end mb-4">
                            {form.length > 0 && form[0].walletAddress === address && (
                                <Button variant="dark" className="h-auto btn-sm rounded-5 px-3" onClick={(e) => handleDashboard(e)}>
                                    Dashboard&nbsp;<FaArrowRightLong size={`15px`} style={{ marginTop: "-3px" }} className="ms-1" />
                                </Button>
                            )}
                        </Col>
                        {form.length > 0 &&
                            <Col lg={4}>
                                <div className="image-wrapper mb-4 text-md-center">
                                    <img src={`${config.imagePath}${form[0].brand_logo}`} alt="" className="img-fluid" />
                                </div>
                            </Col>}
                        {form.length > 0 &&
                            <Col lg={8}>
                                <div className="item_info mb-4">
                                    <Row>
                                        <Col lg={5} className="mb-4">
                                            <h2>{form[0].token_name}</h2>
                                            {form[0].description.length > 100 ?

                                                <p><ReadMore text={form[0].description} maxLength={100} /></p> :
                                                <p>{form[0].description}</p>
                                            }
                                            <div className="item_info_counts">
                                                <ul className="list-unstyled ps-0">
                                                    <li>
                                                        <div className="item_info_type" >
                                                            <a href={`${form[0].website}`} className="text-decoration-none"> Website</a>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="item_info_type" >
                                                            <a href={`${form[0].whitepaper}`} className="text-decoration-none"> Whitepaper </a>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div>
                                                            <img src={`${config.imagePath}${form[0].chainName}.png`} alt="" width={35} />
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="price_attr">
                                            <label className="mb-0 fw-medium">Token Liquidity:</label>
                                            <h5>{(form[0].BALANCES.Token).toFixed(8)}</h5>
                                        </div>
                                        <div className="price_attr">
                                            <label className="mb-0 fw-medium">INFI Liquidity:</label>
                                            <h5>{(form[0].BALANCES.INFI).toFixed(8)}</h5>
                                        </div>
                                        </Col>
                                    </Row>
                                    <div className="d-inline-block">
                                        <div className="price_attr">
                                            <label className="mb-0 fw-medium">Token Price</label>
                                            <h5>{form[0].PRICE}{form[0].token_name}/INFI</h5>
                                        </div>
                                        <div className="price_attr">
                                            <label className="mb-0 fw-medium">Start Date</label>
                                            <h5>{new Date(form[0].create_at).toISOString().split('T')[0]}</h5>
                                        </div>
                                    </div>
                                </div>
                            </Col>}
                        <Col lg={12}>
                            <div>
                                <hr />
                            </div>

                        </Col>
                    </Row>
                    <Row className="justify-content-center ">
                        {form.length > 0 &&
                            <Col lg={7}>
                                <Tab.Container id="left-tabs-example" defaultActiveKey="buy" >
                                    <Row>
                                        <Col sm={12} className="tab-style1">
                                            <Nav variant="pills" className="nav-tabs">

                                                <Nav.Item>
                                                    <div className="">
                                                        <h2>Swap</h2>
                                                    </div>
                                                    <div className="Swapform from_box outlineField px-sm-5 position-relative mt-4 mb-4">
                                                        {isInfiFirst ? (
                                                            <div>
                                                                <Row>
                                                                    <Col lg={12}>

                                                                        <div className="position-relative">
                                                                            {form.length > 0 &&
                                                                                <div className="text-end">
                                                                                    <h6 className="mb-1">Balance: <span className="fw-medium">{form[0].BALANCEINFI}</span></h6>
                                                                                </div>
                                                                            }
                                                                            <Form.Label className="small form-label position-absolute z-2 p-2">
                                                                                You Send
                                                                            </Form.Label>
                                                                            <InputGroup className="mb-0 border rounded-1">
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder=""
                                                                                    aria-describedby="basic-addon2"
                                                                                    name="InfiAmount"
                                                                                    className="rounded-2 form-control z-1 text-end border-0"
                                                                                    style={{ paddingLeft: "50px" }}
                                                                                    value={infi || ""}
                                                                                    onChange={onchangehandlerInfi}
                                                                                    isInvalid={!!error}
                                                                                />
                                                                                <Button
                                                                                    variant="swap"
                                                                                    type="button"
                                                                                    className="border-start border-0 h-auto text-blue  end-0 rounded-2 z-3 cursor-pointer swapbtn"
                                                                                    onClick={(e) => changeCoins(e)}
                                                                                >
                                                                                    <div className="d-flex align-items-center">
                                                                                        <img src={`${config.baseUrl}images/infi.svg`} width="25px" />
                                                                                        &nbsp; INFI
                                                                                    </div>
                                                                                </Button>
                                                                                <Form.Control.Feedback type="invalid" className="text-start">
                                                                                    {error}
                                                                                    </Form.Control.Feedback>
                                                                            </InputGroup>
                                                                            {form[0].error && (
                                                                                <div className="text-danger mt-1">
                                                                                    {form[0].error}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </Col>
                                                                    {form.length > 0 &&
                                                                        <Col lg={12} className="text-end my-3">
                                                                            <div className="swapIcon">

                                                                                <Button variant="primary" className="text-white" onClick={(e) => changeCoins(e)}>
                                                                                    <GrSync />
                                                                                </Button>

                                                                            </div>
                                                                        </Col>}
                                                                    <Col lg={12}>
                                                                    {form.length > 0 && (
                                                                        <div className="mb-1 position-relative" style={{ marginTop: "-1px" }}>
                                                                        {console.log('1234', form[0].tokenData.Token)}
                                                                            <Form.Label className="small form-label position-absolute z-2 p-2">
                                                                                You Get
                                                                            </Form.Label>
                                                                            <InputGroup className="border rounded-1">
                                                                                {console.log("///", form[0].tokenData.Token)}
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    aria-describedby="basic-addon2"
                                                                                    name={`${form[0].token_name}`} 
                                                                                    className=" form-control z-1 text-end border-0"
                                                                                    style={{ paddingLeft: "50px" }}
                                                                                    value={form[0].tokenData.Token || ""}
                                                                                    disabled
                                                                                />
                                                                                <Button
                                                                                    variant="swap"
                                                                                    type="button"
                                                                                    className="border-start border-0 h-auto text-blue end-0 rounded-2 z-3 cursor-pointer swapbtn"
                                                                                    onClick={(e) => changeCoins(e)}
                                                                                >
                                                                                    <div className="d-flex align-items-center">
                                                                                        <img src={`${config.imagePath}${form[0].brand_logo}`} width="25px" />
                                                                                        &nbsp; {form[0].token_name}
                                                                                    </div>
                                                                                </Button>

                                                                            </InputGroup>
                                                                            {form.length > 0 &&
                                                                                <div className="text-end">
                                                                                    <h6 className="mb-0 mt-1">Balance: <span className="fw-medium">{form[0].BALANCETOKEN}</span></h6>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    )}
                                                                    </Col>
                                                            
                                                                    {form.length > 0 && (
                                                                        <Col lg={12}>
                                                                            <div className="d-flex justify-content-between">
                                                                                <div className="mb-3">
                                                                                    <h6 className="mb-0">Price: 1 {form[0].token_name} =  <span className="fw-medium">{form[0].PRICE} INFI </span></h6>
                                                                                    {/* <h6 className="mb-0">SbSe Reserve: <span className="fw-medium">{form[0].SBSE || 0} INFI</span> </h6> */}
                                                                                    <h6 className="mb-0">SbSe PFP/SbSe Treasury: <span className="fw-medium">{form[0].tokenData.Fees || 0} USDT </span></h6>
                                                                                    {/* <h6 className="mb-0">SBSE Pool: <span className="fw-medium">{form[0].tokenData.ToSbsePool || 0} USDT</span> </h6>
                                                                                    <h6 className="mb-0">Treasury: <span className="fw-medium">{form[0].tokenData.ToTreasury || 0} USDT</span> </h6> */}
                                                                                    <h6 className="mb-0">INFI Staking Pool: <span className="fw-medium">{form[0].tokenData.ToStakingPool || 0} INFI</span> </h6>
                                                                                </div>
                                                                            </div>
                                                                            <Button
                                                                                variant="primary"
                                                                                className="text-black w-100 fw-medium mb-3"
                                                                                onClick={(e) => handleInfiToTokenSwap(e)}
                                                                                disabled={!infi || !!form[0].error || error}
                                                                            >
                                                                                Swap
                                                                            </Button>
                                                                        </Col>
                                                                    )}
                                                                </Row>
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                <Row>
                                                                    {form.length > 0 && (
                                                                        <Col lg={12}>
                                                                            <div className=" position-relative" style={{ marginTop: "-1px" }}>
                                                                                {form.length > 0 &&
                                                                                    // <div className="text-end">
                                                                                    //     <h6 className="mb-1">Balance: <span className="fw-medium">{form[0].BALANCEUSDT}</span></h6>
                                                                                    // </div>
                                                                                    <div className="text-end">
                                                                                        <h6 className="mb-0 mt-1">Balance: <span className="fw-medium">{form[0].BALANCETOKEN}</span></h6>
                                                                                    </div>
                                                                                }
                                                                                <Form.Label className="small form-label position-absolute z-2 p-2">
                                                                                    You Send
                                                                                </Form.Label>
                                                                                <InputGroup className="mb-0 border rounded-1">
                                                                                    <Form.Control
                                                                                        type="number"
                                                                                        placeholder=""
                                                                                        aria-describedby="basic-addon2"
                                                                                        name="TokenAmount"
                                                                                        className=" form-control z-1 text-end border-0"
                                                                                        style={{ paddingLeft: "50px" }}
                                                                                        value={token || ""}
                                                                                        onChange={onchangehandlerToken}
                                                                                        isInvalid={!!error}
                                                                                    />
                                                                                    <Button
                                                                                        variant="swap"
                                                                                        type="button"
                                                                                        className="border-start border-0 h-auto text-blue end-0 rounded-2 z-3 cursor-pointer swapbtn"
                                                                                        onClick={(e) => changeCoins(e)}
                                                                                    >
                                                                                        <div className="d-flex align-items-center">
                                                                                            <img src={`${config.imagePath}${form[0].brand_logo}`} width="25px" />
                                                                                            &nbsp; {form[0].token_name}
                                                                                        </div>
                                                                                    </Button>
                                                                                    <Form.Control.Feedback type="invalid" className="text-start">
                                                                                        {error}
                                                                                    </Form.Control.Feedback>
                                                                                </InputGroup>
                                                                                {form[0].error && (
                                                                                <div className="text-danger mt-1">
                                                                                    {form[0].error}
                                                                                </div>
                                                                            )}
                                                                            </div>
                                                                        </Col>
                                                                    )}
                                                                    {form.length > 0 &&
                                                                        <Col lg={12} className="text-end my-3">
                                                                            <div className="swapIcon">
                                                                              
                                                                                <Button variant="primary" className="text-white" onClick={(e) => changeCoins(e)}>
                                                                                    <GrSync />
                                                                                </Button>
                                                                              
                                                                            </div>
                                                                        </Col>}
                                                                    <Col lg={12}>
                                                                        <div className="position-relative mb-1">
                                                                            <Form.Label className="small form-label position-absolute z-2 p-2">
                                                                                You Get
                                                                            </Form.Label>
                                                                            <InputGroup className="mb-0 border rounded-1">
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    aria-describedby="basic-addon2"
                                                                                    name="InfiAmount"
                                                                                    className=" form-control z-1 text-end border-0"
                                                                                    style={{ paddingLeft: "50px" }}
                                                                                    value={form[0].infiData.INFI - form[0].SBSE || ""}
                                                                                    disabled
                                                                                />
                                                                                <Button
                                                                                    variant="swap"
                                                                                    type="button"
                                                                                    className="border-start border-0 h-auto text-blue end-0 rounded-2 z-3 cursor-pointer swapbtn"
                                                                                    onClick={(e) => changeCoins(e)}
                                                                                >
                                                                                    <div className="d-flex align-items-center">
                                                                                        <img src={`${config.baseUrl}images/infi.svg`} width="25px" />
                                                                                        &nbsp; INFI
                                                                                    </div>
                                                                                </Button>
                                                                            </InputGroup>
                                                                            {form.length > 0 &&
                                                                                // <div className="text-end">
                                                                                //     <h6 className="mb-0 mt-1">Balance: <span className="fw-medium">{form[0].BALANCETOKEN}</span></h6>
                                                                                // </div>
                                                                                <div className="text-end">
                                                                                     <h6 className="mb-1">Balance: <span className="fw-medium">{form[0].BALANCEINFI}</span></h6>
                                                                                </div>

                                                                            }
                                                                        </div>
                                                                    </Col>
                                                                    {form.length > 0 && (
                                                                        <Col lg={12}>
                                                                            <div className="d-flex justify-content-between">
                                                                                <div className="mb-3">
                                                                                    <h6 className="mb-0">SbSe PFP/SbSe Treasury: <span className="fw-medium">{form[0].infiData.Fees || 0} USDT </span></h6>
                                                                                    <h6 className="mb-0">SbSe Reserve: <span className="fw-medium">{form[0].SBSE || 0}</span></h6>
                                                                                    {/* <h6 className="mb-0">SbSe Pool: <span className="fw-medium">{form[0].infiData.ToSbsePool || 0} USDT </span></h6>
                                                                                    <h6 className="mb-0">Treasury: <span className="fw-medium">{form[0].infiData.ToTreasury || 0} USDT</span> </h6> */}
                                                                                    <h6 className="mb-0">INFI Staking Pool: <span className="fw-medium">{form[0].infiData.ToStakingPool || 0} INFI </span></h6>
                                                                                </div>
                                                                                <div className="d-flex align-items-center mb-3 switchBtn">
                                                                                </div>
                                                                            </div>
                                                                            <Button
                                                                                variant="primary"
                                                                                className="text-black w-100 fw-medium mb-3"
                                                                                onClick={(e) => handleTokenToInfiSwap(e)}
                                                                                disabled={!token || form[0].error || error}
                                                                            >
                                                                                Swap
                                                                            </Button>
                                                                        </Col>
                                                                    )}
                                                                </Row>
                                                            </div>
                                                        )}


                                                    </div>
                                                </Nav.Item>
                                            </Nav>
                                        </Col>
                                    </Row>
                                </Tab.Container>

                            </Col>}
                    </Row>
                </Container>

            </div>
            <Footer />
        </>
    )
}