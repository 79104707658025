import React from 'react';
import Header from '../Layout/Header';
import Banner from './Banner';
// import Helping from './Helping';
import ProjectList from './ProjectList';
import LaunchProject from './LaunchProject';
import Footer from '../Layout/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import config from '../../config';
export default function Index() {
  const loginDetails = useSelector((state) => state.auth.LoginDetails);
  const navigate = useNavigate();

  if (!loginDetails) {
    navigate(`${config.baseUrl}`);
    window.scrollTo(0, 0);
  }
  return (
    <>
      <Header />
      <Banner />
      {/* <Helping /> */}
      <ProjectList loginDetails={loginDetails} />
      {/* <LaunchProject /> */}
      <Footer />
    </>
  );
}
