import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { FiEye } from "react-icons/fi";
import { FaEyeSlash } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import config from "../../config";
import { loginSchema } from "./Loginschema";
import { Formik } from "formik";
import { registerAction } from "../../Action/action";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import Modal from "react-bootstrap/Modal";

//
import { useWeb3Modal } from "@web3modal/wagmi/react";
import {useAccount} from "wagmi"

export default function Signup() {
  const [formData, setForm] = useState({
    email: "",
    password: "",
    agree: false,
  });
  const [password, setshowPassword] = useState(false);
  const [step, setisStep] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();
  const [type, setType] = useState(0);
  const [showLoading, setShowLoading] = useState(false);

  //
  const { isConnected, address,chainId } = useAccount();
  const { isOpen, open, close } = useWeb3Modal();


  const setShow =  (e) => {
    e.preventDefault()
    console.log(isConnected,address,chainId)
    try {
      open()
    } catch(error) {
      console.log('EEERRR',error)
    }
  }

  const loginDetails = useSelector((state) => state.auth.LoginDetails);
  useEffect(()=>{
    if(loginDetails)
        checkValidity()

      },[])


      const checkValidity=async()=>{
        try{
          if(loginDetails.isLogin)
          navigate(`${config.baseUrl}home`);
          window.scrollTo(0,0)
            }catch(error){
          console.log(error)

        } 
      }

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  let handleSubmit = async (values, { setSubmitting }) => {
    setShowLoading(true)
    try {
      if (!address) {
        handleModalOpen(1);
        return;
      }

      if (!isChecked) {
        handleModalOpen(2);
        return
      }
      setShowLoading(true);
      const payload = {
        email: values.email,
        password: values.password,
        walletAddress: address,
      };
console.log('DATTAAA',payload)
      let res = await registerAction(payload,loginDetails);
      //setShowLoading(true);

      if (res.success) {
        toast.success(res.msg);
        navigate(`${config.baseUrl}login`);
        window.scrollTo(0,0)

        // setTimeout(() => {
        //   navigate(`${config.baseUrl}login`);
        // }, 2000);
      } else {
        toast.error(res.msg);
      }
    } catch (error) {
      setShowLoading(false);
      console.log("Error", Error);
    } finally {
      setSubmitting(false);
    }
  };
  const handleToggleVisibility = () => {
    setshowPassword(!password);
  };

  const handleModalClose = async () => {
    setModalShow(false);
  };
  const handleModalOpen = async (type) => {
    setShowLoading(false)
    console.log(type, "type");
    setType(type);
    setModalShow(true);
  };



  return (
    <>
        <Toaster />
        <section className="login">
          <Container fluid className="p-0">
            <Row className="mx-0" style={{ height: "100vh" }}>
              <Col lg={6} className="p-0 d-none d-lg-block">
                <div className="lg_img">
                  <img
                    src="images/loginBanner.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </Col>

              <Col lg={6} className="p-0 mt-4 mt-lg-0">
                <div className="content_wrapper">
                <Container className=" text-center px-3">
                  <Row className="justify-content-center">
                    <Col lg={7}>
                    {step == 0 ? (
                      <div>
                        <h1>Get Started</h1>

                        <p>
                          Already have an account?{" "}
                          <Link
                            to={`${config.baseUrl}login`}
                            style={{ textDecoration: "none" }}
                          >
                            <span style={{ color: "var(--infi-bg)" }}>
                              Sign in
                            </span>
                          </Link>
                        </p>

                        <div className="or">
                          <div>Or</div>
                        </div>
                        <h4 className="text-gradient">
                          <b>
                          </b>
                        </h4>
                        <div className=" mt-4 mb-4">
                            <div className="connect-btn d-flex justify-content-center">
                              <Button
                              variant="primary"
                              onClick={(e) => setShow(e)}
                              >
                                {isConnected ? address.toString().substring(0,4) + "...." + address.toString().substring(address.length - 4 , address.length) : "Connect Wallet"}
                              </Button>
                              </div>
                        </div>
                        <div className="form">
                          <Formik
                            initialValues={formData}
                            validationSchema={loginSchema}
                            onSubmit={handleSubmit}
                          >
                            {({
                              errors,
                              handleSubmit,
                              handleBlur,
                              handleChange,
                              touched,
                              values,
                              isSubmitting,
                            }) => (
                              <Form
                                onSubmit={(e) => {
                                  e.preventDefault();
                                  handleSubmit(values, {
                                    setSubmitting: () => { },
                                  });
                                }}
                              >
                                <Form.Control
                                  type="text"
                                  placeholder="Email"
                                  name="email"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.email}
                                />
                                {errors.email && touched.email && (
                                  <div
                                    className="text-danger"
                                    style={{ textAlign: "left" }}
                                  >
                                    {errors.email}
                                  </div>
                                )}

                                <div className="position-relative">
                                  <Form.Control
                                    type={password ? "text" : "password"}
                                    className="mt-4"
                                    placeholder="Password"
                                    name="password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                  />
                                  <span
                                    className="position-absolute eye"
                                    onClick={handleToggleVisibility}
                                  >
                                    {password ? <FaEyeSlash /> : <FiEye />}
                                  </span>
                                  {errors.password && touched.password && (
                                    <div
                                      className="text-danger"
                                      style={{ textAlign: "left" }}
                                    >
                                      {errors.password}
                                    </div>
                                  )}
                                </div>

                                <div className="d-flex  mt-4">
                                  <div className="pb-1 pe-1">
                                    <input
                                      type="checkbox"
                                      className=""
                                      checked={isChecked}
                                      onChange={handleCheckboxChange}
                                    />
                                  </div>

                                  <Form.Label className="pb-0 mb-0 text-start">
                                    By signing up, I agree to the{" "}
                                    <a href="./Docs/Privacy Policy.pdf" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and{" "}
                                    <a href="./Docs/Terms & Conditions.pdf" target="_blank" rel="noopener noreferrer">Terms of Service</a>
                                  </Form.Label>
                                </div>

                                <Button
                                  variant="primary"
                                  className="mt-4 w-100 mb-4"
                                  type="submit"
                                  disabled={isSubmitting}
                                >
                                  Sign Up
                                </Button>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    ) : step == 1 ? (
                      <div className="text-center">
                        <img
                          src="images/afterlogin.png"
                          alt=""
                          className="img-fluid"
                        />

                        <h1>Thanks!</h1>

                        <div className="fs-4">
                          We have sent a confirmation email to your registered
                          email address
                          <br />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    </Col>
                    </Row>
                    </Container>

                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <Modal
          size="md"
          show={modalShow}
          onHide={handleModalClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="connectwallet"
        >
          <Modal.Header closeButton className="border-0"></Modal.Header>
          <Modal.Body style={{ marginTop: "-48px" }} className="text-center">
            <div className="text-center px-5">
              {type == 1 ? (

                <p className="fs-5 mb-0 lh-1">
                  Please connect your wallet first to complete registration process
                </p>

              ) : type == 2 ? (
                <p className="fs-5 mb-0 lh-1">
                To register you must agree to the Privacy Policy and Terms Of Service
                </p>

              ) : (
                ""
              )}
            </div>
          </Modal.Body>

        </Modal>
    </>
  );
}